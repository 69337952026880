import React from 'react';

import './Welcome.css';
import logo from "../../assets/LOGO.png";
import { Box } from '@mui/material';

const Welcome = () => {

  return (
    <>
        {/* welcome container */}
        <section className='welcome-sec'>
          <div className='row1'>
            <img src={logo} alt='logo'/>
          </div>
          <div className='row2'>
            <div className='img-line-1'>
              <div className='img-1'>
                
              </div>
              <div className='img-2'>
                
              </div>
            </div>
            <div className='img-line-2'>
              <div className='img-2'>
                
              </div>
              <div className='img-1'>
                
              </div>
            </div>
          </div>
          {/* <Box>
            <img src={homes} style={{ height: '250px', width: '250px'}} alt='homes'/>
          </Box> */}
          <div className='row3'>
            <div className='headline-1'>
              <h3>Help you find the best place to stay</h3>
            </div>
            <div className='headline-2'>
              <p>Find your ideal home or weekend retreat without the hassle of brokers. Tenant Network connects you directly with property owners, making renting easier than ever.</p>
            </div>
          </div>
        </section>
      {/* </div> */}
    </>
  )
}

export default Welcome;