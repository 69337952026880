import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

// import '../Home/index.css';
import '../MyProperties/index.css'
import Card2 from '../../components/card2/Card2';
import { AuthContext } from '../../context/authContext';
import { getWishlist } from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';


const Wishlist = () => {
  const {currentUser, locationVisibility} = useContext(AuthContext);
  const navigate = useNavigate();
  const [properties,setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    const fetchMyWishlist= async() =>{
      try{
        setIsLoading(true);
        const res = await getWishlist(currentUser? currentUser.id:'');
        setProperties(res.data.wishlist);
        setIsLoading(false);
      }catch(err){
        setIsLoading(false);
        navigate("/whatsappLogin");
        localStorage.removeItem('user');
        window.alert("Please Login");
        console.log('wishlist err---',err);
      }
    }

    fetchMyWishlist();
  },[currentUser]);

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  })

  return (
    <>
      <div className='myproperties-page'>
        <Helmet>
          <title>{"Wishlist"}</title>
          <meta name="description" content={"Wishlist"}/>
          <link rel='canonical' href='/wishlist'/>
        </Helmet>
        <div className='myproperties-page-head'>
          <h1>My Wishlist</h1>
        </div>
        {
          isLoading? <div className='home-loader'><LoadingSpinner/></div>
          :
          <>
          {
            properties?
            <div className='myproperties-page-content'>
              <div className='myproperties-cards'>
                {
                  properties.map((property)=>{
                    return <Card2 key={property.id} property={property} wishlistPage={1}/>
                  })
                }
              </div>
            </div>
            :
            ''
          }
          </>
        }
      </div>
    </>
  )
}

export default Wishlist;