import React, { useContext, useEffect, useState } from 'react';
import { MdLibraryAdd } from "react-icons/md";
import {Helmet} from "react-helmet";

import './index.css';
import ServiceCard from '../../components/serviceCard/ServiceCard';
import { getServices } from '../../services/listingService';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';


const Services = () => {
  const {currentUser, currentLocation, locationVisibility} =useContext(AuthContext);
  const [allServices,setAllServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const getAllServices=async ()=>{
      setIsLoading(true);
      try{
        const getServiceResult = await getServices(currentLocation && currentLocation);
        setAllServices(getServiceResult.data.services);
        setIsLoading(false);
      }
      catch(err){
        console.log('getServiceError----',err)
        setIsLoading(false);
      }
    }

    getAllServices();
  },[currentLocation])


  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(true);
  },[])

  return (
    <>
      <div className='services-page'>
        <Helmet>
          <title>{"Services"}</title>
          <meta name="description" content={"Home Services"}/>
          <link rel='canonical' href='/services'/>
        </Helmet>
        <div className='services-page-head'>
          <h1>Services</h1>
          {
            currentUser? currentUser.isAdmin?
            <p><Link to={'/addservices'}>Add Services <MdLibraryAdd/></Link></p> : ""
            : 
            ""
          }
        </div>
        <div className='services-page-content'>
        <div className='services-cards'>
        {
          isLoading? <LoadingSpinner/> :
          <>
            {
              allServices.map((service)=>{
                return <ServiceCard key={service.id} serviceData={service}/>
              })
            }
          </>
        }
        </div>
        </div>
      </div>
    </>
  )
}

export default Services;