import './index.css';
import {Helmet} from "react-helmet";

const Terms = () => {
    return (
        <>
            <div className='terms-page'>
                <Helmet>
                    <title>{"Terms and Conditions"}</title>
                    <meta name="description" content={"Our Terms and Conditions"}/>
                    <link rel='canonical' href='/terms&conditions'/>
                </Helmet>
                <div className='terms-page-head'>
                    <h1>Terms and conditions</h1>
                </div>
                <div className='terms-page-content'>
                    <ol>
                        <li>
                            <h2>Acceptance of Terms</h2>
                            <p>By accessing or using Tenant Network, you agree to be legally bound by these Terms and
                                Conditions. If you do not agree to these terms, please do not use the platform.</p>
                        </li>

                        <li>
                            <h2>Platform Usage</h2>
                            <p><strong>Direct Connections:</strong> Tenant Network facilitates direct connections
                                between
                                property seekers and property owners or co-tenants. We do not act as a broker or
                                intermediary in
                                property transactions.</p>
                            <p><strong>Eligibility:</strong> Users must be at least 18 years old to use the platform.
                            </p>
                            <p><strong>Account Responsibility:</strong> You are responsible for maintaining the
                                confidentiality
                                of your account and password and for restricting access to your device.</p>
                        </li>

                        <li>
                            <h2>Subscription Model</h2>
                            <p><strong>Weekly Subscription:</strong> Users may subscribe to the platform for a weekly
                                fee of INR
                                300.</p>
                            <p><strong>Subscription Limit:</strong> Each user can take a maximum of 2 weekly
                                subscriptions
                                within a 90-day period. This policy is in place to prevent brokers from using the
                                platform.</p>
                            <p><strong>Subscription Renewal:</strong> Users will not be able to renew their subscription
                                beyond
                                the maximum allowed limit within the specified period.</p>
                        </li>

                        <li>
                            <h2>Registration via WhatsApp</h2>
                            <p>Tenant Network requires users to register using WhatsApp login for verification purposes
                                and to
                                prevent unauthorized access by brokers or intermediaries.</p>
                        </li>

                        <li>
                            <h2>Data Usage</h2>
                            <p>By using the platform, you consent to the collection and usage of your registration data.
                                Tenant
                                Network may sell this data to third-party website partners for marketing or business
                                purposes.</p>
                            <p><strong>Privacy:</strong> We take privacy seriously and handle all user data in
                                compliance with
                                applicable data protection regulations. Please refer to our Privacy Policy for further
                                details.
                            </p>
                        </li>

                        <li>
                            <h2>No Brokerage</h2>
                            <p>Tenant Network does not charge brokerage fees. Any financial agreements made between
                                property
                                owners and tenants are solely between the two parties, without any involvement from
                                Tenant
                                Network.</p>
                        </li>

                        <li>
                            <h2>Listing Accuracy</h2>
                            <p>Users are responsible for ensuring that all property listings are accurate, up-to-date,
                                and
                                lawful. Tenant Network is not responsible for the content posted by users.</p>
                            <p>Tenant Network reserves the right to remove any listing that violates these terms or is
                                otherwise
                                inappropriate.</p>
                        </li>

                        <li>
                            <h2>Liability</h2>
                            <p>Tenant Network is a platform for connecting tenants and property owners; we do not
                                guarantee the
                                accuracy or quality of the properties listed or the reliability of any users on the
                                platform.</p>
                            <p>We are not responsible for any disputes, issues, or damages arising between tenants and
                                property
                                owners.</p>
                        </li>

                        <li>
                            <h2>Prohibited Conduct</h2>
                            <p>Users are prohibited from:</p>
                            <ul>
                                <li>Posting false, misleading, or fraudulent listings.</li>
                                <li>Engaging in illegal activities on the platform.</li>
                                <li>Using the platform for purposes other than its intended use (property search and
                                    listing).
                                </li>
                            </ul>
                        </li>

                        <li>
                            <h2>Intellectual Property</h2>
                            <p>All content, trademarks, and logos on Tenant Network are the property of Tenant Network
                                or its
                                licensors. You may not use, reproduce, or distribute any content without prior
                                permission.</p>
                        </li>

                        <li>
                            <h2>Modifications to Terms</h2>
                            <p>Tenant Network reserves the right to modify these Terms and Conditions at any time. Your
                                continued use of the platform after such modifications will constitute your acceptance
                                of the
                                updated terms.</p>
                        </li>

                        <li>
                            <h2>Termination</h2>
                            <p>We reserve the right to terminate your account or access to the platform if you violate
                                these
                                terms or engage in prohibited activities.</p>
                        </li>

                        <li>
                            <h2>Governing Law</h2>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws
                                of
                                India. Any disputes arising under these terms shall be subject to the exclusive
                                jurisdiction of
                                the courts in India.</p>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default Terms;