import React, { useContext, useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { MdAddCircle, MdAccountCircle, MdMenu } from "react-icons/md";

import './Header.css';
import logo from "../../assets/LOGO.png";
import { AuthContext } from '../../context/authContext';
//import {MX_TRACK} from "../../util/Mixpanel";

const Header = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('/');
  const menuRef = useRef(null);
  let pathname = window.location.pathname;

  const handleLogout = async () => {
   /* MX_TRACK(`logout_button_click`, {
      distinct_id: currentUser && currentUser.mobile_no ? currentUser.mobile_no : 'Guest',
    });*/
    try {
      await logout();
      toast.success("Logout successful", {
        duration: 2000
      });
      localStorage.removeItem('user');
      navigate("/whatsappLogin");
    } catch (err) {
      console.log('Logout error:', err.response.data);
      toast.error("Error in logging out", {
        duration: 5000
      });
    }
  };

  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  useEffect(() => {
    // Function to close the menu when clicking outside
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        document.getElementById("showMenu").classList.remove('toogle-menu-show');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for a smooth scrolling effect
    });
  };

  return (
      <>
        <header className='navbar'>
          <div className='nav-left'>
            <div className='logo'>
              <Link to={'/'} onClick={scrollToTop} >
                <img src={logo} alt='logo' />
              </Link>
            </div>
          </div>
          <div className='nav-right'>
            <nav className='nav-menu hideClass'>
              <ul>
                <li><Link to='/about' className={activeLink === '/about' ? 'activePage' : ''}>About</Link></li>
                {/*<li><Link to='/services' className={activeLink === '/services' ? 'activePage' : ''}>Services</Link></li>*/}
                <li><Link to='/wishlist' className={activeLink === '/wishlist' ? 'activePage' : ''}>Wishlist</Link></li>
                <li><Link to='/properties' className={activeLink === '/properties' ? 'activePage' : ''}>Properties</Link></li>
                <li><Link to='/blogs' className={activeLink === '/blogs' ? 'activePage' : ''}>Blogs</Link></li>
              </ul>
            </nav>
          </div>
          <div className='flex'>
            <div className='nav-listing'>
              {currentUser ?
                  <Link to='/addlisting'> Listing <MdAddCircle /> </Link> :
                  <Link to='/whatsappLogin'> Listing <MdAddCircle /> </Link>
              }
            </div>
            &nbsp;&nbsp;
            <div className='nav-profile'>
              {currentUser ?
                  <div className='profile-dropdown'>
                    <div className='profile-dropdown-btn'>
                      <Link to={'/profile'}>
                        <MdAccountCircle className='profile-icon' />
                        {currentUser.full_name}
                      </Link>
                    </div>
                  </div> :
                  <div className='profile-login'>
                    <Link to='/whatsappLogin'>
                      Login/Signup
                    </Link>
                  </div>
              }
            </div>&nbsp;&nbsp;
            <div className='hamburger'>
              <MdMenu onClick={() => document.getElementById("showMenu").classList.toggle('toogle-menu-show')} />
            </div>
          </div>
        </header>

        {/* Full screen menu toggle */}
        <div className='toogle-menu' id='showMenu' ref={menuRef}>
          <div className='toggle-menu-expand'>
            <ul>
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/'>Home</Link></li>
              {currentUser ?
                  <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                            to='/profile'>My Profile</Link></li> :
                  <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                            to='/whatsappLogin'>My Profile</Link></li>}
              {/*<li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/properties'>Properties</Link></li>*/}
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/about'>About</Link></li>
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/contactus'>Contact Us</Link></li>
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/blogs'>Blogs</Link></li>
             {/* <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/blogs'>Partner Brand</Link></li>*/}
              {/*<li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/services'>Service</Link></li>*/}
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/faq'>FAQs</Link></li>
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/search'>Explore</Link></li>
              <li><Link onClick={() => document.getElementById("showMenu").classList.remove('toogle-menu-show')}
                        to='/wishlist'>Wishlist</Link></li>
              {currentUser ?
                  <button className='logout-button' onClick={handleLogout}>Logout</button> :
                  <button className='logout-button' onClick={() => navigate('/whatsappLogin')}>Login</button>
              }
            </ul>
          </div>
        </div>
      </>
  );
}

export default Header;
