import React, { useState } from "react";
import "./FAQ.css"; // Import CSS for styling

const faqs = [
    {
        question: "Book the same bed/house I visited?",
        answer: "Once you book the house, please log on to TenantNetwork on the website/app and click on 'Scheduled Visits'. Click on the bed/house you wish to reserve and click on 'Book Now'."
    },
    {
        question: "Unable to book, house is sold out?",
        answer: "We're sorry to hear that the house of your choice is no longer available to book. But don't worry, we have 30k+ other properties listed on our platform and a great team here to ensure you find the perfect house! Browse through all available options and make sure to pay a small token amount online next time, to ensure your bed/house is reserved."
    },
    {
        question: "Want to know more about the property?",
        answer: "We can assist you personally during a scheduled visit to the house which will be conducted by the property manager at a time of your convenience. We also display all information you should know about the house transparently on our platform. Click on the house of your choice and read about amenities available, rent, security deposit, etc."
    },
    {
        question: "Is the house pet friendly?",
        answer: "You can get an idea if the house is pet-friendly by asking your property manager during your scheduled visit. In case you've missed doing so, you can call us on +91 8904242424 between 8 AM to 8 PM and we'll be happy to assist you."
    },
    {
        question: "How accurate are the property's images?",
        answer: "While onboarding a property on our platform from the owner, we gather photographs of the entire house to depict its features earnestly and accurately. The same is displayed on our website/app."
    },
    {
        question: "Will I be assigned the same bed/house that I have booked?",
        answer: "Yes! Our platform gives a live view of properties available including the exact bed and room in the case of shared spaces. So, what you book is what you get."
    },
    {
        question: "Does TenantNetwork charge brokerage?",
        answer: "Our homes are brokerage-free. However, we do collect an on-boarding fee. Your on-boarding charge is a small fee you pay for the documentation, profile verification, and the soft furnishing that we provide."
    },
    {
        question: "What if I delay payment?",
        answer: "If you delay payment of security deposit and onboarding charges, your move in might be delayed. Sometimes, your booking might also stand cancelled upon delays or non-payments."
    },
    {
        question: "Does TenantNetwork provide house accessories?",
        answer: "House accessories like curtains, bedsheets, cutlery, pillow covers, dustbins, cleaning equipment, etc. are generally required to maintain basic hygiene in the property. Their need and usage vary with personal consumption styles. Hence, we advise you to feel free to purchase or replace these (and similar items) at your discretion of requirement and use."
    },
    {
        question: "What are onboarding charges?",
        answer: "Onboarding fees enable us to ensure the property you've booked is handed over in time with proper assistance and with all legal compliances. The charges vary based on city and municipality which facilitates legal documentation."
    },
    {
        question: "Can I choose house amenities?",
        answer: "In fully furnished houses, we offer a standard package of amenities along with furniture which include internet connection, DTH connection, etc. In a co-living space, it is not feasible for us, as a property manager, to provide varied sets of amenities to each of the tenants in the same house."
    },
    {
        question: "Didn't receive OTP to complete profile?",
        answer: "Please check if the contact number you've mentioned in the 'My Profile' section is correct. Should you continue to face this issue, please write to us in the form below. Our team will assist you shortly."
    },
    {
        question: "Don't use the amenities, will I be exempted from charges?",
        answer: "The amenities offered in the house are meant for usage by all tenants living in the house. In a shared living space, it is not feasible for us as a property manager to provide varied sets of amenities to each of the tenants. Hence, your cooperation in making best use of the offered standard package would be appreciated."
    },
    {
        question: "How can I find a list of house amenities?",
        answer: "We display all information you should know about the house transparently on our platform. Click on the house of your choice, scroll to 'House Amenities' and read about amenities available, rent, security deposit, etc."
    },
    {
        question: "Do all houses have parking?",
        answer: "Parking facility availability varies from house to house. Click on the house of your choice and check for parking availability in the property."
    },
    {
        question: "Remove furniture and reduce the rent?",
        answer: "The amenities offered in the house are meant for usage by all tenants living in the house. In a shared living space, it is not feasible for us as a property manager to provide varied sets of amenities to each of the tenants."
    },
    {
        question: "Need maid/cook?",
        answer: "We do not offer maid / cook services through TenantNetwork. Hence, we suggest you reach out to your neighbours or building security for contacts of maids or cooks in your area."
    },
    {
        question: "What services are not provided by TenantNetwork?",
        answer: "TenantNetwork holds no authority over matters outside the property we manage, such as building/society services (e.g. garbage disposal, security, lift, etc.) and network providers’ serviceability."
    },
    {
        question: "Can I share my full room with a friend?",
        answer: "For the safety of other tenants and the property, we do not allow unverified tenants to stay in the premises without completing document verification and booking with us."
    },
    {
        question: "Can I book the property without a visit?",
        answer: "Yes, you can book any property online directly. However, visiting the property prior to booking will ensure you choose the right property for yourself."
    }
];


const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h1 className="faq-title">Frequently Asked Questions</h1>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? "active" : ""}`}
                    >
                        <div className="faq-question" onClick={() => toggleFAQ(index)}>
                            {faq.question}
                        </div>
                        <div className="faq-answer" style={{ display: activeIndex === index ? "block" : "none" }}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
