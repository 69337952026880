import * as React from 'react';
import { restore } from '../services/userService';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';

const SessionHelper = ({children}) => {
  const { setCurrentUser } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const onLoad = async () => {
    try{
      const {userSessionExpired} = await restore();
      if(userSessionExpired){
        console.log("Debug: User Logged Out!!")
        setCurrentUser(null);
        navigate('/whatsappLogin')
        localStorage.removeItem('user');
      }
    }catch(err){
      console.log("restore error---",err)
    }
  }

  React.useEffect(()=>{
    onLoad();
  },[])

  return (
    <>
      { children }
    </>
  )
}

export default SessionHelper;