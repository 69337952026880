import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";

import "./Login.css";
import { MdClose,MdRemoveRedEye } from "react-icons/md";
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/authContext';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

const LoginComponent = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [user,setUser] = useState({
    email:'',
    password:''
  });
  const {login} = useContext(AuthContext);
  const navigate = useNavigate();

  let name,value;
  const handleInput=(e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value
    setUser({...user,[name]:value})
  }

  //calling api
  const handleSubmit= async (e)=>{
    e.preventDefault();
    try{
      setIsLoading(true);
      await login(user);
      toast.success("login success",{
        duration:2000
      });
      setIsLoading(false);
      navigate("/");
    }
    catch(err){
      console.log(err.response.data.message);
      setIsLoading(false);
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }

  const tooglePass = () =>{
    const passField = document.getElementById('tooglePass');
    passField.type==='password'? passField.type='text' : passField.type='password';
  }

  return (
    <>
      <section className="login-container">
        <div className="login-head">
          <h1>Login</h1>
          <p>Login to get more <br/> recommended properties</p>
        </div>
        <div className="login-form">
          <form>
            <input name="email" onChange={handleInput} value={user.email} type="text" placeholder="Email"/>
            <div className="pass-toogle">
              <input id="tooglePass" className="pass-input" name="password" onChange={handleInput} value={user.password} type="password" placeholder="Password"/>
              <span className="eye-icon">
                <MdRemoveRedEye className="eye" onClick={tooglePass}/>
              </span>
            </div>

            <span><Link to="/resetpassword">Forget Password</Link></span>
            {
              isLoading? <LoadingSpinner/> : <button onClick={handleSubmit}>Login</button>
            }
            <span className="signup-btn">Don't have an account? <Link to="/signup">SignUp</Link> </span>
          </form>
        </div>

        {/* close btn */}
        <div className="close-con">
          <Link to={`/`}>
            <MdClose/>
          </Link>
        </div>
      </section>
    </>
  )
}

export default LoginComponent;