import React, {useContext, useEffect, useState} from 'react';
import {Button, Input} from '@chakra-ui/react';
import { MdClose, MdNoteAdd } from "react-icons/md";
import toast from 'react-hot-toast';

import './index.css';
import { publishBlogService, uploadImageService, updateBlogService, getBlogCategories } from '../../services/blogService';
import { AuthContext } from '../../context/authContext';
import { useLocation, useNavigate } from 'react-router-dom';

const contentFormat = {
  subheading:'',
  description:'',
  image:'',
  hyperlink:'',
}


const AddBlog = () => {
  const {currentUser,locationVisibility} = useContext(AuthContext);
  const navigate = useNavigate();
  const state = useLocation().state;
  const [categories,setCategories] = useState([]);
  const [blogMain, setBlogMain] = useState({
    title: state? state.title : '',
    summary: state? state.summary : '',
    image: state? state.image : '',
    category: state? state.category : "Decor",
    visibility: state? state.visibility : 'public'
  });

  const [description, setDescription] = useState(state? state.description : []);

  const addNewContent=()=>{
    setDescription([...description,contentFormat]);
  }


  // image upload
  const uploadImage =async (e,index) =>{
    if (e.target.files && e.target.files[0]) {
      if (new RegExp("image/*").test(e.target.files[0].type)) {
        try{
          const form = new FormData();
          form.append('file',e.target.files[0]);
          
          const uploadImageRes = await uploadImageService(form);
          
          if(index<0){
            setBlogMain({...blogMain,['image']:uploadImageRes.data.data.publicUrl})
          }
          else{
            let newData= description.map((data,n)=>{
              if(index===n){
                return {...data,['image']:uploadImageRes.data.data.publicUrl}
              }
              else{
                return data;
              }
            })

            setDescription(newData);
          }
        }
        catch(error){
          console.log('uploadImage error---',error)
        }
      }
      else {
        window.alert('Please select image format');
      }
    }
  }

  let name,value;
  const handleMainContent = (e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setBlogMain({...blogMain,[name]:value});
  }

  const handleSubContent = (e,index)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    let newData= description.map((data,n)=>{
      if(n===index){
        return {...data,[name]:value}
      }
      else{
        return data;
      }
    })
    setDescription(newData);
  }

  const removeSubContent=(index)=>{
    setDescription(description.filter((data,n)=>{
      if(index!==n){
        return data;
      }
    }));
  }


  // publish
  const publishBlog=async()=>{
    try{
      const blogData ={
        user_id:currentUser.id,
        ...blogMain,description
      }
      let publishResponse = state? await updateBlogService({id:state.id, ...blogData}) : await publishBlogService(blogData);

      toast.success(publishResponse.data.message, {
        duration: 3000
      });
      navigate(`/blogs`);
    }
    catch(err){
      console.log('publish blog error',err);
      err.response ?
        toast.error(err.response.data.message, {
          duration: 5000
        })
        :
        toast.error('something went wrong', {
          duration: 5000
        });
    }
  }

  useEffect(()=>{
    const fetchBlogCategories=async()=>{
      try{
        const res = await getBlogCategories();
        setCategories(res.data);
      }catch(err){
        window.alert("something went wrong");
        console.log('myproperties err---',err);
      }
    }
    fetchBlogCategories();
  },[])

  useEffect(()=>{
    const changeLocation=async(visibility)=>{
      try{
        await locationVisibility(visibility);
      }
      catch(err){
        console.log('error in enabling location visibility')
      }
    }
    changeLocation(false);
  })



  return (
    <>
      <div className='addblog-page'>
        <div className='addblog-page-head'>
          <h1>Add Blog</h1>
        </div>

        {/* content */}
        <div className='addblog-content'>
          <div className='banner-img-con'>
            {blogMain.image &&
              <img style={{width:"100%", height:"400px"}} src={blogMain.image} alt='banner-img'/>
            }
          </div>
          <div className='addblog-head'>
            <div className='head-left'>
              <input name='title' value={blogMain.title} onChange={handleMainContent} className='title-field' type='text' placeholder='Title'/>
              <textarea name='summary' value={blogMain.summary} onChange={handleMainContent} className='summary-field' type='text' placeholder='Summary'/>
            </div>
            <div className='head-right'>
              <div className='cat-div'>
                <select name='category' value={blogMain.category} onChange={handleMainContent}>
                {
                  categories.map((data,index)=>{
                    return <option key={index} value={data.category}>{data.category}</option>
                  })
                }
                </select>
              </div>
              <div className='visibility-sec'>
                <span>
                  <input type="radio" defaultChecked={blogMain.visibility === 'private'} onChange={handleMainContent} id="private" name="visibility" value="private"/>
                  <label htmlFor="private"> Private  &nbsp;</label> 
                </span>
                <span>
                  <input type="radio" defaultChecked={blogMain.visibility === 'public'} onChange={handleMainContent} id="public" name="visibility" value="public"/>
                  <label htmlFor="public"> Public  &nbsp;</label> 
                </span>

              </div>
              <div className='banner-img-btn'>
                <Input onChange={(e)=>uploadImage(e,-1)} id="imageInput" type="file" hidden />
                <Button
                  className='chakra-btn'
                  as="label"
                  htmlFor="imageInput"
                >
                Add Image
                </Button>
              </div>
            </div>
          </div>

          {/* add description */}
          { description && 
            description.map((data,index)=>{
              let newId= `imageInput${index}`;
              return(
                  <div className='blog-desc-sec' key={index}>
                    <div className='blog-desc'>
                      <div className='data-input'>
                        <input value={data.subheading} onChange={(e)=>handleSubContent(e,index)} name='subheading' type='text' placeholder='Sub-heading'/>
                        <textarea value={data.description} onChange={(e)=>handleSubContent(e,index)} name='description' className='sub-desc' type='text' placeholder='Description' />
                        <input value={data.hyperlink} onChange={(e)=>handleSubContent(e,index)} name='hyperlink' type='text' placeholder='Hyperlink'/>
                      </div>
                      <div className='add-img-sec'>
                        <Input onChange={(e)=>uploadImage(e,index)} id={newId} type="file" hidden />
                        <Button
                          className='chakra-btn'
                          as="label"
                          htmlFor={newId}
                        >
                          Add Image
                        </Button>
                      </div>
                    </div>
                    <div className='blog-desc-img-sec'>
                      <div className='img-container'>
                      {data.image &&
                        <img src={data.image} style={{width:"100%", height:"400px"}} alt='img' />
                      }
                      </div>
                    </div>

                    {/* remove button */}
                    <div className='remove-desc'>
                      <span onClick={()=>removeSubContent(index)}><MdClose className='remove-btn'/></span>
                    </div>
                  </div>
              )
            })
          }

          <div className='add-desc-btn'>
            <button onClick={addNewContent}>Add New Content <MdNoteAdd/></button>
          </div>
        </div>


        {/* publish button */}
        <div className='submit-blog-con'>
          <button onClick={publishBlog}>Publish</button>
        </div>
      </div>
    </>
  )
}

export default AddBlog;