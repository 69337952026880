import axios from "axios";
import { BASE_URL } from "./helper";

const restore = async () => {
  const res = await axios.post(`${BASE_URL}/user/restore`, {}, {withCredentials: true});
  return res.data;
}

const userLogin = async (userData) => {
    const res = await axios.post(`${BASE_URL}/user/login`, {...userData},{withCredentials:true});
    return res;
}

const userLogout = async () => {
    const res = await axios.post(`${BASE_URL}/user/logout`,{},{withCredentials:true});
    return res;
}

const userSignup = async (userData) => {
    const res = await axios.post(`${BASE_URL}/user/register`, {...userData});
    return res;
}

const updateUserProfile = async (userData) => {
    const res = await axios.post(`${BASE_URL}/user/updateUser`, userData,{withCredentials:true});
    return res;
}

const getAbout = async () => {
    const res = await axios.get(`${BASE_URL}/user/about`,{withCredentials:true});
    return res;
}

const getUserDetails = async (email) => {
    const res = await axios.get(`${BASE_URL}/user/getUserDetails?email=${email}`,{withCredentials:true});
    return res;
}

const forgetPassword = async (email) => {
    const res = await axios.post(`${BASE_URL}/user/forgetPassword`,{email},{withCredentials:true});
    return res;
}

const sendOtpService = async (mobile_no) => {
    const res = await axios.post(`${BASE_URL}/user/login/otp`,{mobile_no},{withCredentials:true});
    return res;
}

const verifyOtp = async (user) => {
    const res = await axios.post(`${BASE_URL}/user/login/verify`,{...user},{withCredentials:true});
    return res.data;
}

const updatePassword = async (user) => {
    const res = await axios.post(`${BASE_URL}/user/resetPassword`,{...user},{withCredentials:true});
    return res;
}

const getSubscriptionDetails = async()=>{
  const res = await axios.get(`${BASE_URL}/user/getSubscriptionDetails`,{withCredentials:true});
  return res;
}


export {
  restore,
  userLogin, 
  userLogout, 
  userSignup, 
  updatePassword, 
  getAbout, 
  updateUserProfile, 
  getUserDetails, 
  forgetPassword,
  getSubscriptionDetails,
  sendOtpService,
  verifyOtp
};