exports.propertyTypesConstant = {
  APARTMENT : 'Apartment',
  WEEKEND_HOME : 'Weekend Home',
  PG_HOSTEL : 'PG/Hostel',
  CO_LIVING : 'Co-Living',
}

exports.listedByConstant = {
  TENANT : 'Tenant',
  OWNER : 'Owner',
  BROKER : 'Broker',
  FLATMATE : 'Flatmate',
}

exports.configurationConstant = {
  _1BHK : '1BHK',
  _2BHK : '2BHK',
  _3BHK : '3BHK',
  _4BHK : '4BHK',
  _5BHK : '5BHK',
  _6BHK : '6BHK',
  SINGLE_SHARING : 'Single Sharing',
  DOUBLE_SHARING : 'Double Sharing',
  TRIPLE_SHARING : 'Triple Sharing',
  FOUR_SHARING : 'Four Sharing',
}

exports.tenantTypeConstant = {
  NO_PREFERENCE : 'No Preference',
  MALE : 'Male',
  FEMALE : 'Female',
  FAMILY : 'Family',
  BACHELOR: 'Bachelor'
}

exports.foodAllowedConstant = {
  NO_PREFERENCE : 'No Preference',
  VEG : 'Veg',
  NONVEG : 'Non-Veg'
}

exports.OccupancyConstant = {
  FULL : 'Full House',
  HALL_OCCUPANCY : 'Hall Occupancy',
  SINGLE_SHARING : 'Single Sharing',
  DOUBLE_SHARING : 'Double Sharing',
  PRIVATE_ROOM : 'Private Room',
  DORM : 'Dorm',
  ANY : 'Any'
}

exports.furnishingStatusConstant = {
  FULLY_FURNISHED : 'Furnished',
  SEMI_FURNISHED : 'Semi-Furnished',
  UNFURNISHED : 'Unfurnished'
}

exports.brokerageConstants = {
  ZERO : '0 Days',
  _15_DAYS_RENT : '15 Days',
  _30_DAYS_RENT : '30 Days'
}

exports.homeCategoriesConstants = {
  RECOMMENDED : 'Recommended',
  WEEKEND_HOME : 'Weekend Home',
  BEST_DEALS : 'Best Deals'
}

exports.amenities = [
  'Wifi Available',
  'Swimming Pool',
  'Lift',
  'Gym',
  'Gas Pipeline',
  'Club House',
  'Caretaker',
  'Joggers Track',
  'Spa',
  'Theatre',
  'Meditation Zone',
  'Medical Store',
  'Power Backup',
  'Security',
  'Gated Community',
  'CCTV',
  'Parking',
  'Indoor Games',
  'Outdoor Games',
  'Garden',
  'Banquet',
  'Departmental Store',
  'Laundry Facility',
]

