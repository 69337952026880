import React, { useContext, useState } from 'react';
import { MdPhone, MdOutlineArrowOutward } from "react-icons/md";
import toast from 'react-hot-toast';

import './ServiceCard.css';
import { AuthContext } from '../../context/authContext';
import { deleteService } from '../../services/listingService';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import ContactInfo from '../contactInfo/ContactInfo';


const ServiceCard = (props) => {
  const {currentUser} = useContext(AuthContext);
  let serviceData = props.serviceData? props.serviceData : {};
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popup,setPopup] = useState(false);

  const reloadEffect = () => {
    setReload(!reload);
  };

  const handleDelete=async(e)=>{
    e.preventDefault();
    setIsLoading(true);
    try{
      const deleteRes = await deleteService(serviceData.id);
      if(deleteRes){
        serviceData.deleted = 1;
        toast.success("Service Deleted",{
          duration:3000
        });
        setIsLoading(false);
        reloadEffect();
      }
    }
    catch(err){
      console.log('deleteServiceErr---',err);
      setIsLoading(false);
      toast.error(err.response.data.message,{
        duration:3000
      });
    }
  }

  return (
    <>
      {
        serviceData.deleted?
        '':
      <div className='service-card-container'>
        <div className='service-img-sec'>
          <img src={serviceData.image} alt={'service'}/>
        </div>
        <div className='service-name'>
          <h2>{serviceData.service_name ? serviceData.service_name : " "}</h2>
        </div>
        <div className='service-btns'>
          <a href={serviceData.url} target='_blank' rel='noreferrer'>
            <button className='btn1'>Visit <MdOutlineArrowOutward/></button>
          </a>
          <button onClick={()=>setPopup(true)} className='btn2'>Call Now <MdPhone/></button>
          {
            currentUser? currentUser.isAdmin?
              <>
                {
                  isLoading? <LoadingSpinner/>:
                  <button onClick={handleDelete} className='btn3'>Delete</button> 
                }
              </>
              : ''
            : 
            ""
          }
        </div>
      </div>
      }
      <ContactInfo className='filter-pop' head={'Contact'} trigger={popup} setTrigger={setPopup}>
        <div className='contact-popup-info'>
          <div className='contact-data'>
            <ul>
              <li>Contact No. -</li>
            </ul>
            <ul>
              <li>{serviceData.contact}</li>
            </ul>
          </div>
        </div>
      </ContactInfo>
    </>
  )
}

export default ServiceCard