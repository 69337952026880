import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import toast from 'react-hot-toast';

import "./Login.css";
import { userSignup } from "../../services/userService";
import { MdClose, MdRemoveRedEye } from "react-icons/md";
import cities from '../../data/cities.json';
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const SignupComponent = () => {
  const [isLoading,setIsLoading]=useState(false)
  const [user,setUser] = useState({
    full_name:'',
    email:'',
    mobile_no:'',
    user_type:'tenant',
    location:'',
    password:''
  });

  const navigate = useNavigate();

  let name,value;
  const handleInput=(e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value
    setUser({...user,[name]:value})
  }

  //calling api
  const handleSubmit= async (e)=>{
    e.preventDefault();
    try{
      setIsLoading(true)
      await userSignup(user);
      toast.success("User Registered Successfully!",{
        duration:2000
      });
      setIsLoading(false);
      navigate("/login");
    }
    catch(err){
      console.log(err.response.data.message);
      setIsLoading(false);
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }

  const tooglePass = () =>{
    const passField = document.getElementById('tooglePass');
    passField.type==='password'? passField.type='text' : passField.type='password';
  }

  return (
    <>
      <section className="login-container">
        <div className="login-head">
          <h1>Sign-up</h1>
        </div>
        <div className="login-form signup-form">
          <form>
            <input name="full_name" onChange={handleInput} value={user.full_name} type="text" placeholder="Full Name"/>
            <input name="email" onChange={handleInput} value={user.email} type="text" placeholder="Email"/>
            <input name="mobile_no" onChange={handleInput} value={user.mobile_no} type="text" placeholder="Phone no."/>

            <select onChange={handleInput} name="user_type" id="userType">
              <option value={"tenant"}>Tenant</option>
              <option value={"broker"}>Broker</option>
              <option value={"owner"}>Owner</option>
              <option value={"flatmate"}>Flatmate</option>
            </select>
            <select onChange={handleInput} name="location" id="location">
              <option value={''}>choose location</option>
                {cities.map((city)=>{
                  return <option key={city.id} value={city.name}>{city.name}</option>
                })}
            </select>
            
            {/* <input name="location" onChange={handleInput} value={user.location} type="text" placeholder="Location"/> */}
            <div className="pass-toogle">
              <input id="tooglePass" className="pass-input" name="password" onChange={handleInput} value={user.password} type="password" placeholder="Password"/>
              <span className="eye-icon">
                <MdRemoveRedEye className="eye" onClick={tooglePass}/>
              </span>
            </div>
            {
              isLoading? <LoadingSpinner/> : <button onClick={handleSubmit}>Signup</button>
            }
            <span className="signup-btn">Already have an account? <Link to="/login">Login</Link> </span>
          </form>
        </div>

        {/* close btn */}
        <div className="close-con">
          <Link to={`/`}>
            <MdClose/>
          </Link>
        </div>
      </section>
    </>
  )
}

export default SignupComponent;