import React, {useContext, useState, useEffect} from 'react';
import {MdEast, MdOutlineChevronLeft, MdOutlineChevronRight} from "react-icons/md";
import {Helmet} from "react-helmet";
import img_1 from '../../assets/Banner/img3.jpg'
import img_2 from '../../assets/Banner/img5.jpg'
import img_3 from '../../assets/Banner/img6.jpg'
import './index.css';
import '../Search/index.css';
import {Link, useNavigate} from 'react-router-dom';
import Card from '../../components/card/Card';
import {AuthContext} from '../../context/authContext';
import {getListings, getCategories, getCount} from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import cities from "../../data/cities.json";
import toast from "react-hot-toast";
import {getUserDetails} from "../../services/userService";
//import {MX_TRACK} from "../../util/Mixpanel";

const handleLeftScroll = (id) => {
    let box = document.getElementById(id);
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
}
const handleRightScroll = (id) => {
    let box = document.getElementById(id);
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
}

const Home = () => {
    const navigate = useNavigate();

    const {currentUser, currentLocation, changeLocation, locationVisibility} = useContext(AuthContext);
    const [recommendedProperties, setRecommendedProperties] = useState([]);
    const [bestDealsProperties, setBestDealsProperties] = useState([]);
    const [weekendHomes, setWeekendHomes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState();
    const {setCurrentUser} = useContext(AuthContext);

    const [activeButton, setActiveButton] = useState();

    const handleButtonClick = (type) => {
        setActiveButton(type);
    };

    const handleSearchButtonClick = (type) => {
        /*  MX_TRACK(`Search_Button_clicked`, {
              distinct_id: currentUser && currentUser.mobile_no ? currentUser.mobile_no : 'Guest',
              location: currentLocation,
              property_type: type,
          });*/
    }

    const handleLocation = async (e) => {
        try {
            await changeLocation(e.target.value);

        } catch (err) {
            console.log(err.response.data);
            toast.error("location not fetched", {
                duration: 5000
            });
        }
    }

    useEffect(() => {
        const fetchProperties = async () => {
            setIsLoading(true);
            try {
                const res = await getListings(currentLocation, currentUser ? currentUser.id : '');
                setRecommendedProperties(res.data.recommended);
                setBestDealsProperties(res.data.best_deals);
                setWeekendHomes(res.data.weekend_home);

                setIsLoading(false);
            } catch (err) {
                // window.alert("something went wrong");
                console.log('homepage err---', err);
                setIsLoading(false);
            }
        }

        fetchProperties();
    }, [currentLocation]);

    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true);
            try {
                const res = await getCategories(currentLocation, currentUser ? currentUser.id : '');
                setCategories(res.data.categories);
                if (res.data.categories.length > 0) {
                    setActiveButton('Apartment');
                }
                setIsLoading(false);
            } catch (err) {
                console.log('homepage err---', err);
                setIsLoading(false);
            }
        }
        fetchCategories();

    }, [currentLocation]);

    useEffect(() => {
        const changeLocation = async (visibility) => {
            try {
                await locationVisibility(visibility);
            } catch (err) {
                console.log('error in enabling location visibility')
            }
        }
        changeLocation(true);
    }, [])

    let trusted_by = "14,678";
    let avl_cities = "12"
    const [livePropertyCount, setLivePropertyCount] = useState(0);

    useEffect(() => {
        // Replace with your API call
        const fetchGetCount = async () => {
            setIsLoading(true);
            try {
                const res = await getCount(currentLocation, currentUser ? currentUser.id : '');
                const another_data = res.data.anotherData[0];
                setLivePropertyCount(another_data.count_all); // Update state
                setIsLoading(false);
            } catch (err) {
                console.log('homepage err---', err);
                setIsLoading(false);
            }
        }
        fetchGetCount();
    }, [currentLocation]);


    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const res = await getUserDetails(currentUser.email);
                setCurrentUser({...res.data})
            } catch (err) {
            }
        }
        fetchUserDetails();
    }, []);


    // Function to handle card click and navigate to the search page
    const handleCardClick = (propertyType) => {
        navigate('/search', {
            state: {
                search: '', // If you want to pass any search term
                filters: {listed_by: propertyType},
                city: currentLocation
            }
        });
    };

    return (<>
        <Helmet>
            <title>{"Tenant Network"}</title>
            <meta name="description" content={"Welcome to Tenant Network Home Page"}/>
            <link rel='canonical' href='/home'/>
        </Helmet>

        {/**/
            <div className="landing-page">
                <div className="overlay">
                    <header className="header-content">
                        <div className="trusted_middle">
                            <p>Trusted by thousands of people</p>
                        </div>
                        <h1>Zero brokerage, connect directly. <br/> Rent smarter!</h1>

                        <div className="search-bar">
                            <div className="location-dropdown">
                                <select className='custom-select' value={currentLocation ? currentLocation : ''}
                                        onChange={handleLocation}
                                        name='city'>
                                    {cities.map((city) => {
                                        return <option key={city.id} value={city.name}>{city.name}</option>
                                    })}
                                </select>

                            </div>
                            <input onChange={(e) => setSearch(e.target.value)} type='text'
                                   placeholder='Search House, Apartments etc.'/>
                            <button className='btn_class' type="button"
                                    onClick={() => handleSearchButtonClick(activeButton)}><Link to={`/search`} state={{
                                search,
                                filters: {property_type: activeButton},
                                city: currentLocation
                            }}>Search</Link></button>
                        </div>
                        <div className="property-types">

                            {categories.map((category, index) => (
                                <button
                                    key={`${category.id}-${index}`}
                                    className={activeButton === category.property_type ? 'active' : ''}
                                    onClick={() => handleButtonClick(category.property_type)}
                                >
                                    {category.property_type}
                                </button>
                            ))}
                        </div>

                    </header>

                </div>
            </div>

            /**/}


        <div className='home-page'>

            {/* property cards recommended section*/}
            {isLoading ? <div className='home-loader'><LoadingSpinner/></div> : <>
                {recommendedProperties.length ? <div className='cards-view-con'>
                    <div className='cards-view-head'>
                        <h3>Recommended Properties</h3>
                        <h5><Link to={'/view?recommended_properties'}
                                  state={{data: recommendedProperties, head: 'Recommended Properties'}}>View All</Link>
                        </h5>
                    </div>
                    {/* cards */}
                    <div className='cards-row' id='recomProp'>
                        {recommendedProperties.map((property, index) => {
                            if (index > 4) {
                                return;
                            }
                            return <Card key={property.id} property={property}/>
                        })}
                    </div>
                    <div className='scroll-btn-sec'>
                        <MdOutlineChevronLeft onClick={() => handleLeftScroll('recomProp')} className='icon'/>
                        <MdOutlineChevronRight onClick={() => handleRightScroll('recomProp')} className='icon'/>
                    </div>
                </div> : ''}
            </>}

            {/* property cards best deals section*/}
            {isLoading ? <div className='home-loader'><LoadingSpinner/></div> : <>
                {bestDealsProperties.length ? <div className='cards-view-con'>
                    <div className='cards-view-head'>
                        <h3>Best Deals</h3>
                        <h5><Link to={'/view?best_deals'} state={{data: bestDealsProperties, head: 'Best Deals'}}>View
                            All</Link></h5>
                    </div>
                    {/* cards */}
                    <div className='cards-row' id='bestDeals'>
                        {bestDealsProperties.map((property, index) => {
                            if (index > 4) {
                                return;
                            }
                            return <Card key={property.id} property={property}/>
                        })}
                    </div>
                    <div className='scroll-btn-sec'>
                        <MdOutlineChevronLeft onClick={() => handleLeftScroll('bestDeals')} className='icon'/>
                        <MdOutlineChevronRight onClick={() => handleRightScroll('bestDeals')} className='icon'/>
                    </div>
                </div> : ''}
            </>}

            <div className="premium-properties">
                <div className="text-content">
                    <h2>Are you looking for Premium Properties?</h2>
                    <p>View and book your appointment with our partners</p>
                    <button className="view-properties-btn"><Link to='/search'>View Properties &rarr;</Link></button>
                </div>
                <div className="images">
                    <div className="image-1" aria-hidden="true"></div>
                    <div className="image-2" aria-hidden="true"></div>
                </div>
            </div>


            {/* home service */}
            {/* <div className='home-service-con'>
                <div className='home-service-desc'>
                    <h3>Home Service</h3>
                    <p>We strive to provide an environment that fosters a high quality of living</p>
                    <Link to={'/services'}>
                        <button>Visit <MdEast/></button>
                    </Link>
                </div>
            </div>*/}

             <div className="hero-section_middle">
                <div className="curved-background_middle">
                    <div className="content_middle">
                        <div className="card-container_middle">
                            <div className="card_middle card-overlap_middle" onClick={() => handleCardClick('Tenant')}>

                                <div className="tag_middle">Broker Listed</div>
                                <img src={img_3} alt="Stanza Living Dunkirk House" className="card-image_middle"/>
                                <div className="card-info_middle">
                                    <h3></h3>
                                    <p>Click here.. </p>
                                    <p className="price_middle">Explore property <br/> listed by brokers</p>
                                </div>
                            </div>
                            <div className="card_middle card-overlap_middle" onClick={() => handleCardClick('Owner')}>
                                <div className="tag_middle">Tenant Listed</div>
                                <img src={img_1} alt="WeWork Forum" className="card-image_middle"/>
                                <div className="card-info_middle">
                                    <h3></h3>
                                    <p>Click here.. </p>
                                    <p className="price_middle">Explore property <br/> listed by tenants</p>
                                </div>
                            </div>
                            <div className="card_middle card-overlap_middle" onClick={() => handleCardClick('Broker')}>
                                <div className="tag_middle">Owner Listed</div>
                                <img src={img_2} alt="Stanza Living Dunkirk House" className="card-image_middle"/>
                                <div className="card-info_middle">
                                    <h3></h3>
                                    <p>Click here.. </p>
                                    <p className="price_middle">Explore property <br/> listed by property owners</p>
                                </div>
                            </div>

                        </div>
                        <div className="text-content_middle">
                            <h1>Elevate <strong>Your Business Visibility </strong> with Tenant Network</h1>
                            <p>Leverage India's <strong>Leading Platform </strong> for Spaces</p>
                            <div className="buttons_middle">
                                <Link className="primary-button_middle" to='/wishlist'> List Your Space</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stats_middle">
                    <div className="stat-item_middle">
                        <h2>{trusted_by}</h2>
                        <p>Trusted By</p>
                    </div>
                    <div className="stat-item_middle">
                        <h2>{livePropertyCount}</h2>
                        <p>Live Properties</p>
                    </div>
                    <div className="stat-item_middle">
                        <h2>{avl_cities}</h2>
                        <p>Cities</p>
                    </div>
                </div>
            </div>




            {/* property cards weekend home section section*/}
            {isLoading ? <div className='home-loader'><LoadingSpinner/></div> : <>
                {weekendHomes.length ? <div className='cards-view-con'>
                    <div className='cards-view-head'>
                        <h3>Weekend Homes</h3>
                        <h5><Link to={'/view?weekend_homes'} state={{data: weekendHomes, head: 'Weekend Homes'}}>View
                            All</Link></h5>
                    </div>
                    {/* cards */}
                    <div className='cards-row' id='bestDeals'>
                        {weekendHomes.map((property, index) => {
                            if (index > 4) {
                                return;
                            }
                            return <Card key={property.id} property={property}/>
                        })}
                    </div>
                    <div className='scroll-btn-sec'>
                        <MdOutlineChevronLeft onClick={() => handleLeftScroll('bestDeals')} className='icon'/>
                        <MdOutlineChevronRight onClick={() => handleRightScroll('bestDeals')} className='icon'/>
                    </div>
                </div> : ''}
            </>}
        </div>


    </>)
}

export default Home
