import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {MdCurrencyRupee, MdLocationOn, MdEdit, MdDelete, MdMan2, MdWoman2, MdGroupAdd} from "react-icons/md";
import {FaUser, FaWallet} from "react-icons/fa";
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import toast from 'react-hot-toast';

import './Card2.css';
import {AuthContext} from '../../context/authContext';
import {deleteListing, addToWishlist} from '../../services/listingService';
import {listedByConstant, propertyTypesConstant} from '../../constants/property';
import tnmini from "../../assets/logo-mini.png";
import PropertyCarousel from "../SmoothSlider";

const Card2 = (props) => {
    const {currentUser} = useContext(AuthContext);
    const propertyDetails = props.property ? props.property : {};
    const [reload, setReload] = useState(false);

    const reloadEffect = () => {
        setReload(!reload);
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        if (window.confirm('Confirm to Delete')) {
            try {
                const delRes = await deleteListing({id: propertyDetails.id});
                if (delRes.data.success) {
                    toast.success("Property Deleted", {
                        duration: 3000
                    });
                    propertyDetails.deleted = 1;
                    reloadEffect();
                }
            } catch (err) {
                console.log("delete err--", err);
                err.response ?
                    toast.error(err.response.data.message, {
                        duration: 5000
                    })
                    :
                    toast.error('something went wrong', {
                        duration: 5000
                    });
            }
        }
    }

    const addWishlist = async (listing_id) => {
        try {
            if (currentUser) {
                propertyDetails.wishlisted ? propertyDetails.wishlisted = 0 : propertyDetails.wishlisted = 1;

                await addToWishlist(listing_id, currentUser.id);

                if (props.wishlistPage) {
                    propertyDetails.deleted = 1;
                }

                reloadEffect();
                toast.success("Wishlist Updated", {
                    duration: 3000
                });
            } else {
                toast.error("You need to Login first", {
                    duration: 3000
                });
            }
        } catch (err) {
            console.log("wishlist err--", err);
            err.response ?
                toast.error(err.response.data.message, {
                    duration: 5000
                })
                :
                toast.error('something went wrong', {
                    duration: 5000
                });
        }
    }

    return (
        <>
            {
                propertyDetails.deleted ?
                    ''
                    :
                    <div className='card-container'>
                        <div className='card-img-sec'>
                            <Link to={`/propertydetail/${propertyDetails.id}`}>
                                {/* <img src={propertyDetails && propertyDetails.images[0]} alt='img'/>*/}
                                <PropertyCarousel images={propertyDetails && propertyDetails.images}/>
                            </Link>
                            <div onClick={() => addWishlist(propertyDetails && propertyDetails.id)}
                                 className='wishlist-icon'>
                                {
                                    propertyDetails.wishlisted ?
                                        <AiFillHeart className='icon'/>
                                        :
                                        <AiOutlineHeart className='icon'/>
                                }
                            </div>
                            <div className='property-type-tag'>
                                <p>{propertyDetails && propertyDetails.property_type}</p>
                            </div>
                        </div>
                        <div className='card-details-sec'>
                            <div className='property-desc'>
                                <Link to={`/propertydetail/${propertyDetails.id}`}>
                                    <h3>{propertyDetails && propertyDetails.configuration + ' ' + propertyDetails.property_type + ' in ' + propertyDetails.locality}</h3>
                                </Link>
                                <p><span
                                    className='locationIconColor'><MdLocationOn/> </span> {propertyDetails && propertyDetails.locality + ' ' + propertyDetails.city}
                                </p>
                            </div>

                            <div className='property-manage'>
                                <p>
                                    {propertyDetails && propertyDetails.listed_by === listedByConstant.TENANT ?
                                        <>
                                            <img className='icon_card' src={tnmini} alt='tn-logo'/>
                                            <span>Managed by</span> {propertyDetails && propertyDetails.listed_by}
                                        </> :
                                        <>
                                            <FaUser className='icon_card'/>
                                            <span>Managed by</span> {propertyDetails && propertyDetails.listed_by}
                                        </>
                                    }
                                </p>

                            </div>


                            <div className="property-card">
                                <div className="property-price">
                                    {
                                        propertyDetails &&
                                        propertyDetails.property_type === propertyTypesConstant.WEEKEND_HOME ?
                                            <div className="price-item">
                    <span
                        className="amount"><MdCurrencyRupee/>{propertyDetails && new Intl.NumberFormat('en-IN').format(propertyDetails.rent)}</span>
                                                <span className="label">Rent<br/>/day</span>
                                            </div>
                                            :
                                            <div className="price-item">

                    <span
                        className="amount"><MdCurrencyRupee/>{propertyDetails && new Intl.NumberFormat('en-IN').format(propertyDetails.rent)}</span>
                                                <span className="label">Rent<br/>/month</span>
                                            </div>
                                    }

                                    <div className="price-item">
                                    <span className="amount">
                                    {
                                        propertyDetails && (() => {
                                            const tenantType = propertyDetails.tenant_type.toLowerCase();
                                            if (tenantType === "male") {
                                                return (
                                                    <>
                                                        <MdMan2/> <br/>
                                                        {propertyDetails.tenant_type}
                                                    </>
                                                );
                                            } else if (tenantType === "female") {
                                                return (
                                                    <>
                                                        <MdWoman2/> <br/>
                                                        {propertyDetails.tenant_type}
                                                    </>
                                                );
                                            } else if (tenantType === "family" || tenantType === "no preference") {
                                                return (
                                                    <>
                                                        <MdGroupAdd/> <br/>
                                                        {tenantType === "no preference" ? "Open for all" : propertyDetails.tenant_type}
                                                    </>
                                                );
                                            }
                                        })()
                                    }
                                </span>
                                    </div>

                                    <div className="price-item">
              <span
                  className="amount"><FaWallet/><MdCurrencyRupee/>{propertyDetails && new Intl.NumberFormat('en-IN').format(propertyDetails.deposit)}</span>
                                        <span className="label">Security<br/>Deposit</span>
                                    </div>
                                </div>
                            </div>

                            <div className='card-btn'>
                                {
                                    props.edit ?
                                        <div className='edit-del-btn'>
                                            <Link to={`/addlisting?edit=2`} state={propertyDetails}>
                                                <button className='edit-btn'><MdEdit/> Edit</button>
                                            </Link>
                                            <Link to={``}>
                                                <button onClick={handleDelete} className='del-btn'><MdDelete/> Delete
                                                </button>
                                            </Link>
                                        </div>
                                        :
                                        <div className='view-btn-sec'>
                                            <Link to={`/propertydetail/${propertyDetails.id}`}>
                                                <button>View Details</button>
                                            </Link>
                                        </div>
                                }
                            </div>
                        </div>

                    </div>
            }
        </>
    )
}

export default Card2;