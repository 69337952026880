import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import { Link, useNavigate } from 'react-router-dom';
import { MdLocationCity, MdPrivacyTip, MdPeopleAlt } from "react-icons/md";
import { FaFileContract } from "react-icons/fa";
import { AuthContext } from '../../context/authContext';
import { BsFillPatchCheckFill, BsFillHouseAddFill } from "react-icons/bs";
import {Helmet} from "react-helmet";

import { getUserDetails, updateUserProfile } from '../../services/userService';
import toast from 'react-hot-toast';
import {
  PROD_COMPANY_ID,
  PROD_COUNTRY_ID,
  PROD_TOKEN,
} from '../../services/helper';

const servicesData =(data1,data2)=>{
  return (
    <div className='menu-card-row'>
      <Helmet>
          <title>{"Profile"}</title>
          <meta name="description" content={"User Profile"}/>
          <link rel='canonical' href='/profile'/>
        </Helmet>
      <Link to={data1.link}>
        <div className='menu-card'>
          <div className='menu-card-data'>
            <div className='card-logo'>
              {data1.logo}
            </div>
            <div className='card-text'>
              <p>{data1.service}</p>
            </div>
          </div>
        </div>
      </Link>
      <Link to={data2.link}>
        <div className='menu-card'>
          <div className='menu-card-data'>
            <div className='card-logo'>
            {data2.logo}
            </div>
            <div className='card-text'>
              <p>{data2.service}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

const handleEdit=(e)=>{
  e.preventDefault();
  if(document.getElementById('Name').disabled === true){
    document.getElementById('Name').disabled = false;
    document.getElementById('DOB').disabled = false;
    document.getElementById('Email').disabled = false;
    document.getElementById('Gender').disabled = false;
    document.getElementById("editButton").classList.add('hide-btn');
    document.getElementById("updateButton").classList.remove('hide-btn');
  }
  else{
    document.getElementById('Name').disabled = true;
    document.getElementById('DOB').disabled = true;
    document.getElementById('Email').disabled = true;
    document.getElementById('Gender').disabled = true;
    document.getElementById("editButton").classList.remove('hide-btn');
    document.getElementById("updateButton").classList.add('hide-btn');
  }
}

const Profile = () => {
  const {currentUser,logout} = useContext(AuthContext);
  const navigate = useNavigate();
  const [userData,setUserData]= useState({
    full_name:"",
    dob:'',
    phone:'',
    email:'',
    gender:''
  });

  let name,value;
  const handleUserData = (e)=>{
    name=e.target.name;
    value=e.target.value;
    setUserData({...userData,[name]:value})
  }

  //calling api
  const updateProfile= async (e)=>{
    e.preventDefault();
    try{
      await updateUserProfile(userData);
      handleEdit(e);
      toast.success("Profile Updated",{
        duration:3000
      });
    }
    catch(err){
      navigate("/whatsappLogin");
      localStorage.removeItem('user');
      window.alert("something went wrong");
    }
  }

  const showMyAccountWidget = async () => {
    const COMPANY_ID =PROD_COMPANY_ID;
    const COUNTRY_ID = PROD_COUNTRY_ID;
    const TOKEN = PROD_TOKEN;
    const USER = JSON.parse(localStorage.getItem('user'));
    document.getElementById("myAccountWidget").innerHTML = `<stykite-widget 
                company-id="${COMPANY_ID}" 
                country-id="${COUNTRY_ID}"
                email="${USER.email ? USER.email : 'null'}"
                customer-identifier="${USER.id}"
                widget-type="MY_ACCOUNT"
                button-css="color: white; background-color: #1976d2; border: 1px #1976d2 solid; border-radius: 5px"
                api-key="${TOKEN}"></stykite-widget>`;
  }


  useEffect(()=>{
    const fetchUserDetails= async() =>{
      try{
        const res = await getUserDetails(currentUser.email);
        setUserData({...res.data});
      }catch(err){
        window.alert("something went wrong");
        localStorage.removeItem('user');
        navigate("/whatsappLogin");
        console.log('profile err---',err);
      }
    }
    currentUser && showMyAccountWidget() && fetchUserDetails();
  },[currentUser]);

  return (
    <>
      {/* <Header/> */}
      <div className='profile-page'>
        <div className='profile-page-head'>
          <h1>My Profile</h1>
        </div>

        <div className='profile-page-content'>
          <div className='profile-details'>
            <form>
              <input onChange={handleUserData} value={userData.full_name || ''} name='full_name' id='Name' type='text'
                     placeholder='Name' disabled/>
              <input onChange={handleUserData} value={userData.dob || ''} name='dob' id='DOB' type='text'
                     onBlur={(e) => (e.target.type = "text")} onFocus={(e) => (e.target.type = "date")}
                     placeholder='Date Of Birth' disabled/>
              <input value={userData.mobile_no || ''} name='mobile_no' id='Phone' type='text' placeholder='Phone no.'
                     disabled/>
              <input onChange={handleUserData} value={userData.email || ''} name='email' id='Email' type='email'
                     placeholder='Email' disabled/>

              <select name='gender' value={userData.gender} onChange={handleUserData} id='Gender' disabled>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </select>

              <button onClick={handleEdit} id='editButton' className='edit-btn'>Edit Profile</button>
              <button onClick={updateProfile} id='updateButton' className='update-btn hide-btn'>Update Profile</button>
              <Link to={'/whatsappLogin'}>
                <button onClick={logout} className='logout-btn'>Logout</button>
              </Link>
            </form>
          </div>

          <div className='profile-page-menu'>
            {servicesData({service: 'Visits', link: '/visits', logo: <MdLocationCity/>}, {
              service: 'About Us',
              link: '/about',
              logo: <MdPeopleAlt/>
            })}
            {servicesData({
              service: 'Terms & Conditions',
              link: '/terms&conditions',
              logo: <FaFileContract/>
            }, {service: 'Privacy Policy', link: '/privacypolicy', logo: <MdPrivacyTip/>})}
            {servicesData({
              service: 'My Listed Properties',
              link: '/myproperties',
              logo: <BsFillPatchCheckFill/>
            }, {service: 'Add Property', link: '/addlisting', logo: <BsFillHouseAddFill/>})}
          </div>
        </div>

        <div id="myAccountWidget">
        </div>
      </div>

      {/* <Footer/> */}
    </>
  )
}

export default Profile;
