import React,{useContext, useState} from 'react';
import {Button, Input} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import toast  from 'react-hot-toast';

import './index.css';
import img from '../../assets/service.png';
import { AuthContext } from '../../context/authContext';
import { addService } from '../../services/listingService';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import cities from '../../data/cities.json';

const AddServices = () => {
  const {currentUser, currentLocation} = useContext(AuthContext)
  const [localImage,setlocalImage] = useState('');
  const [image,setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [serviceData,setServiceData] = useState({
    user_id: currentUser? currentUser.id : '',
    service_name: '',
    url:'',
    contact:null,
    location: currentLocation? currentLocation : ''
  })
  const navigate = useNavigate();


  // img upload
  const handleUpload=(e)=>{
    if (e.target.files && e.target.files[0]) {
      if(new RegExp("image/*").test(e.target.files[0].type)){
        setlocalImage(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
      }
      else{
        window.alert('Please select image format');
      }
    }
  }
  let name, value;
  const handleInput=(e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setServiceData({...serviceData,[name]:value});
  }


  // api request
  const handleSubmit=async(e)=>{
    e.preventDefault();
    setIsLoading(true);
    try{
      const form = new FormData();
      form.append('file',image);
      form.append('body',JSON.stringify({...serviceData}));

      const addServiceResult = await addService(form);
      setIsLoading(false);
      toast.success(addServiceResult.data.message,{
        duration:5000
      })
      navigate('/services');
    }
    catch(err){
      err.response?
      toast.error(err.response.data.message,{
        duration:5000
      })
      :
      toast.error('something went wrong',{
        duration:5000
      });
      setIsLoading(false);
      console.log('addservice err--',err)
    }
  }


  return (
    <>
      <div className='add-service-page'>
        <div className='add-service-head'>
          <h1>Add Service</h1>
        </div>
        <div className='add-service-container'>
          <div className='img-container'>
            <div className='img-con'>
            {
              localImage.length?
              <img src={localImage} alt={'service img'}/> :
              <img src={img} alt={'service img'}/>
            }
            </div>
            <div className='add-img-btn'>
              <Input id="imageInput" type="file" hidden onChange={handleUpload} />
              <Button
                className='chakra-btn'
                as="label"
                htmlFor="imageInput"
              >
              Add Image
              </Button>
            </div>
          </div>
          <div className='data-container'>
            
            <form>
              <input type='text' name='service_name' onChange={handleInput} placeholder='Service Name'/>
              <input type='text' name='url' onChange={handleInput} placeholder='Enter Link'/>
              <input type='number' name='contact' onChange={handleInput} placeholder='Contact No.'/>
              <select value={serviceData.location? serviceData.location : ''} onChange={handleInput}  name='location' >
                {cities.map((city)=>{
                  return <option key={city.id} value={city.name}>{city.name}</option>
                })}
              </select>
            </form>
              {
                isLoading? <LoadingSpinner/> :
                  <button onClick={handleSubmit}>Submit</button>
              }
          </div>
        </div>
      </div>
    </>
  )
}

export default AddServices