import React, {useState, useEffect, useContext} from 'react';
import {useParams, Link, useNavigate} from 'react-router-dom';
import {
    MdEdit, MdOutlineChevronLeft, MdOutlineChevronRight,
    MdDelete, MdBed, MdOutlinePeople, MdOutlineKey, MdChair, MdLocationCity,
    MdLocationOn, MdAddHomeWork, MdCurrencyRupee
} from "react-icons/md";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {GiForkKnifeSpoon} from "react-icons/gi";
import {CgTag} from "react-icons/cg";
import {HiBadgeCheck} from "react-icons/hi";
import moment from 'moment';
import toast from 'react-hot-toast';
import {Helmet} from "react-helmet";
import dayjs from 'dayjs';


import './index.css'
import LOGO from '../../assets/logo-mini.png'
import {getListingById, setVisits} from '../../services/listingService';
import {AuthContext} from '../../context/authContext';
import CarouselComponent from '../../components/carousel/Carousel';
import tnmini from '../../assets/logo-mini.png';
import Amenities from '../../components/amenities/Amenities';
import {addToWishlist, deleteListing} from '../../services/listingService';
import {propertyTypesConstant} from '../../constants/property';
import {getRazorpayOrder, setRazorpayTransaction} from '../../services/paymentService';
import SubscriptionDialog from '../../dialogs/SubscriptionDialog';
import {Box} from '@mui/material';
import ContactInfoDialog from '../../dialogs/ContactInfoDialog';
//import {MX_TRACK} from "../../util/Mixpanel";


const tableRowWrap = (x, y) => {
    return (
        <tr>
            <td>
                <div className='table-content'>
                    <div className='content-icon'>
                        {x.icon}
                    </div>
                    <div className='content-data'>
                        <h2>{x.name}</h2>
                        <span>{x.title}</span>
                    </div>
                </div>
            </td>
            <td>
                <div className='table-content'>
                    <div className='content-icon'>
                        {y.icon}
                    </div>
                    <div className='content-data'>
                        {
                            y.name === 'Assured' ? (
                                    <div className='overview-special'><img src={tnmini} alt='tn-logo'/> <h2> Assured</h2>
                                    </div>) :
                                <h2>{y.name}</h2>
                        }
                        <span>{y.title}</span>
                    </div>
                </div>
            </td>
        </tr>
    )
}

const handleLeftScroll = (id) => {
    let box = document.getElementById(id);
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
}
const handleRightScroll = (id) => {
    let box = document.getElementById(id);
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
}


const ListingDetail = () => {
    const {currentUser, locationVisibility} = useContext(AuthContext)
    const {setCurrentUser} = useContext(AuthContext);
    const [propertyDetails, setPropertyDetail] = useState({});
    const [openSubscription, setOpenSubscription] = useState(false);
    const amenities = propertyDetails.amenities ? propertyDetails.amenities : [];
    const params = useParams();
    const navigate = useNavigate();
    const [reload, setReload] = useState(false);
    const [popup, setPopup] = useState(false);

    const reloadEffect = () => {
        setReload(!reload);
    };

    // add to wishlist
    const addWishlist = async (listing_id) => {
        try {
            if (currentUser) {
                propertyDetails.wishlisted ? propertyDetails.wishlisted = 0 : propertyDetails.wishlisted = 1;
                reloadEffect();

                await addToWishlist(listing_id, currentUser.id);
                toast.success("Wishlist Updated", {
                    duration: 3000
                });
            } else {
                toast.error("You need to Login first", {
                    duration: 3000
                });
            }
        } catch (err) {
            console.log("wishlist err--", err);
            err.response ?
                toast.error(err.response.data.message, {
                    duration: 5000
                })
                :
                toast.error('something went wrong', {
                    duration: 5000
                });
        }
    }

    // overview
    const overviewSection = () => {
        return (
            <>
                <div className='overview-head'>
                    <h1>Overview</h1>
                    <p onClick={() => addWishlist(propertyDetails && propertyDetails.id)}>{propertyDetails.wishlisted ? <>
                        <span>Remove from Wishlist</span> <AiFillHeart/></> : <><span>Add to Wishlist</span>
                        <AiOutlineHeart/></>}</p>
                </div>
                <div className='overview-content'>
                    {/* table content */}
                    <table>
                        {tableRowWrap({
                            icon: <MdBed/>,
                            name: propertyDetails.configuration && propertyDetails.configuration,
                            title: 'Configuration'
                        }, {
                            icon: <MdLocationCity/>,
                            name: propertyDetails.property_type && propertyDetails.property_type,
                            title: 'Property Type'
                        })}
                        {tableRowWrap({
                            icon: <MdOutlinePeople/>,
                            name: propertyDetails.tenant_type && propertyDetails.tenant_type,
                            title: 'Preffered Tenant'
                        }, {
                            icon: <MdChair/>,
                            name: propertyDetails.furnishing_status && propertyDetails.furnishing_status,
                            title: 'Furnishing Status'
                        })}
                        {tableRowWrap({
                            icon: <GiForkKnifeSpoon/>,
                            name: propertyDetails.food_allowed && propertyDetails.food_allowed,
                            title: 'Food Allowed'
                        }, {icon: <MdLocationOn/>, name: propertyDetails.city && propertyDetails.city, title: 'City'})}
                        {tableRowWrap({
                            icon: <MdOutlineKey/>,
                            name: propertyDetails.available_from && moment(propertyDetails.available_from).format("LL"),
                            title: 'Possession'
                        }, {icon: <HiBadgeCheck className='dark-icon'/>, name: 'Assured', title: ''})}

                    </table>
                </div>
                <div className='overview-btn'>
                    <button onClick={handleInfo}>Get Contact Info</button>
                </div>
            </>
        )
    }

    const handleDelete = async (e) => {
        e.preventDefault();
        if (window.confirm('Confirm to Delete:')) {
            try {
                const delRes = await deleteListing({id: propertyDetails.id});
                if (delRes.data.success) {
                    toast.success("Property Deleted", {
                        duration: 3000
                    });
                    navigate("/");
                }
            } catch (err) {
                console.log("delete err--", err);
                err.response ?
                    toast.error(err.response.data.message, {
                        duration: 5000
                    })
                    :
                    toast.error('something went wrong', {
                        duration: 5000
                    });
            }
        }
    }


    // razorpay
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;

            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
    }

    const displayRazorpay = async (amount) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        if (!res) {
            toast.error('Connection Error', {
                duration: 5000
            });
            return;
        }

        const razorpay_creds = await getRazorpayOrder();

        const options = {
            key: razorpay_creds.data.RAZORPAY_KEY,
            currency: "INR",
            amount: amount * 100,
            name: "Tenant Network",
            description: "Thanks for Visiting",
            image: LOGO,

            handler: async function (response) {
                const successPayload = {
                    transaction_id: response.razorpay_payment_id,
                    user_id: currentUser.id,
                    email: currentUser.email,
                    property_id: propertyDetails.id,
                }
                const saveTransaction = await setRazorpayTransaction(successPayload);
                setCurrentUser(saveTransaction.user)

                reloadEffect();
                if (saveTransaction) {
                    toast.success("Payment Successful", {
                        duration: 5000
                    });
                }
            },
            prefill: {
                name: currentUser.full_name,
                email: currentUser.email,
                contact: currentUser.mobile_no
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    // add to visits
    const addToVisits = async () => {
        try {
            const successPayload = {
                user_id: currentUser.id,
                email: currentUser.email,
                property_id: propertyDetails.id,
            }
            const saveTransaction = await setVisits(successPayload);
            setPopup(true);
            if (saveTransaction) {
                toast.success("Property added to Visits", {
                    duration: 5000
                });
            }
        } catch (err) {
            console.log('addToVisits Error')
        }
    }

    // get info effect
    const handleInfo = () => {
       /* MX_TRACK(`get_contact_info_button_click`, {
            distinct_id: currentUser && currentUser.mobile_no ? currentUser.mobile_no : 'Guest',
        });*/

        console.log("Please Login First",currentUser)
        if (!currentUser) {
            window.alert("Please Login First")
            localStorage.removeItem('user');
            navigate('/whatsappLogin');
        } else {
            if (
                propertyDetails.user_id === currentUser.id ||
                (currentUser?.subscription_upto && dayjs().isBefore(dayjs(currentUser?.subscription_upto)))
                || currentUser.isAdmin === 1
            ) {
                setPopup(true);
            } else {
                setOpenSubscription(true);
            }
        }
    }


    useEffect(() => {
        const getListing = async () => {
            try {
                const res = await getListingById(params.listed_id, currentUser ? currentUser.id : "");
                setPropertyDetail(res.data['propertyListing']);
            } catch (err) {
                console.log("fetch listing err--", err);
            }
        }

        getListing();
    }, [params.listed_id]);

    useEffect(() => {
        const changeLocation = async (visibility) => {
            try {
                await locationVisibility(visibility);
            } catch (err) {
                console.log('error in enabling location visibility')
            }
        }
        changeLocation(false);
    }, [])

    useEffect(() => {
     /*   MX_TRACK(`to_property_detail_screen_navigated`, {
            distinct_id: currentUser && currentUser.mobile_no ? currentUser.mobile_no : 'Guest',
        });*/
    })


    return (
        <>
            <Box id='listingDetails' className='listing-detail-page'>
                <Helmet>
                    <title>{"Tenant Network"}</title>
                    <meta name="description" content={"Tenant Network"}/>
                    <link rel='canonical' href='/propertydetails'/>
                </Helmet>
                <div className='main-frame'>
                    <div className='main-frame-row1'>
                        <div className='row1-col1'>
                            <div className='title-box'>
                                <div className='box-head'>
                                    <div className='d-flex'><span className='property-manage_p'> Managed by {propertyDetails && propertyDetails.listed_by} </span>
                                    </div> </div>
                                <div className='box-data'>
                                    <h4 className='font_weight'>{propertyDetails.configuration && propertyDetails.configuration + ' ' + propertyDetails.property_type + ' in ' + propertyDetails.locality}</h4>
                                </div>
                            </div>
                        </div>
                        <div className='row1-col2'>
                            <div className='price-box'>
                                <div className='box-head'>
                                    <h2><MdAddHomeWork/>Rent</h2>
                                </div>
                                <div className='box-data'>
                                    {
                                        propertyDetails &&
                                        propertyDetails.property_type === propertyTypesConstant.WEEKEND_HOME ?
                                            <h4 className='font_weight'>
                                                <MdCurrencyRupee/>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.rent))}<span>/day</span>
                                            </h4>
                                            :
                                            <h4 className='font_weight'>
                                                <MdCurrencyRupee/>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.rent))}<span>/month</span>
                                            </h4>
                                    }
                                    <h5 className='font_weight'>
                                        <span>Deposit- <MdCurrencyRupee/></span>{propertyDetails && (new Intl.NumberFormat('en-IN').format(propertyDetails.deposit))}
                                    </h5>
                                </div>
                            </div>
                            <div className='location-box'>
                                <div className='box-head'>
                                    <h2><MdLocationOn/> Location</h2>
                                </div>
                                <div className='box-data'>
                                    <h4 className='font_weight'>{propertyDetails.locality && propertyDetails.locality + ' ' + propertyDetails.city}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main-frame-row2'>
                        {/* images column */}
                        <div className='main-frame-img-col'>
                            <CarouselComponent className='main-img'
                                               images={propertyDetails.images && propertyDetails.images}/>
                        </div>

                        {/* overview column */}
                        <div className='main-frame-overview-col'>
                            {overviewSection()}
                        </div>
                    </div>
                </div>

                {/* row 3, 4 */}
                <div className='main-frame-overview-col overview-mobile'>
                    {overviewSection()}
                </div>
                <div className='other-details'>
                    <div className='amenities'>
                        <div className='other-details-head'>
                            <h1>Facilities</h1>
                        </div>
                        <div className='facility-details'>
                            <div className='facility-details-data' id='recomProp'>
                                {<Amenities amenities={amenities}/>}
                            </div>
                            <div className='facility-scroll-btns'>
                                <MdOutlineChevronLeft onClick={() => handleLeftScroll('recomProp')} className='icon'/>
                            </div>
                            <div className='facility-scroll-btns facility-right-btn'>
                                <MdOutlineChevronRight onClick={() => handleRightScroll('recomProp')} className='icon'/>
                            </div>
                        </div>
                    </div>
                    {/* description */}
                    <div className='description'>
                        <div className='other-details-head'>
                            <h1>Description</h1>
                        </div>
                        <div className='other-details-data'>
                            <p>
                                {propertyDetails.description && propertyDetails.description}
                            </p>
                        </div>
                    </div>
                    <div className='owner-details'>
                        <div className='owner'>
                            <p>Property Posted
                                By- <span>{propertyDetails.full_name && propertyDetails.full_name}({propertyDetails && propertyDetails.listed_by})</span>
                            </p>
                        </div>
                        <div className='owner-contact'>
                            <button onClick={handleInfo}>Get Contact Info</button>
                        </div>
                    </div>
                </div>
                {
                    currentUser && currentUser.isAdmin === 1 &&
                    <div className='admin-access-btn'>
                        <Link to={`/addlisting?edit=2`} state={propertyDetails}>
                            <button className='edit-btn'><MdEdit/> Edit</button>
                        </Link>
                        <Link to={``}>
                            <button onClick={handleDelete} className='del-btn'><MdDelete/> Delete</button>
                        </Link>
                    </div>
                }
            </Box>

            <ContactInfoDialog
                popup={popup}
                setPopup={setPopup}
                property={propertyDetails}
                addToVisits={addToVisits}
            />

            <SubscriptionDialog
                open={openSubscription}
                onClose={() => setOpenSubscription(false)}
                title={'Subcribe to Tenant Network'}
                message={"We're excited to offer you a subscription to our service!"}
                onSuccess={displayRazorpay}
            />
        </>
    )
}

export default ListingDetail;