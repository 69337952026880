import './index.css';
import {Helmet} from "react-helmet";

const Privacy = () => {
return (
    <>
      <div className='terms-page'>
        <Helmet>
          <title>{"Privacy Policy"}</title>
          <meta name="description" content={"Tenant Network Privacy Policies"}/>
          <link rel='canonical' href='/privacypolicy'/>
        </Helmet>
        <div className='terms-page-head'>
          <h1>Privacy Policy</h1>
        </div>
        <div className='terms-page-content'>
          <p><strong>Effective Date: 1st October 2024</strong></p>

          <p>Tenant Network is committed to protecting your privacy. This Privacy Policy explains how we collect, use,
            disclose, and safeguard your information when you visit our platform. By using Tenant Network, you consent
            to the data practices described in this policy.</p>

          <ol>
            <li>
              <h2>Information We Collect</h2>
              <p>We may collect personal information from you in various ways, including but not limited to when
                you:</p>
              <ul>
                <li>Register on our platform using WhatsApp login</li>
                <li>Subscribe to our services</li>
                <li>Interact with our customer service</li>
              </ul>
              <p>The personal information we may collect includes:</p>
              <ul>
                <li>Name</li>
                <li>Contact information (e.g., phone number)</li>
                <li>Location details</li>
                <li>Payment information (for subscription fees)</li>
                <li>Any other information you provide us voluntarily</li>
              </ul>
            </li>

            <li>
              <h2>How We Use Your Information</h2>
              <p>We may use the information we collect from you for the following purposes:</p>
              <ul>
                <li>To facilitate direct connections between tenants and property owners</li>
                <li>To process subscription payments</li>
                <li>To improve our platform and user experience</li>
                <li>To communicate with you regarding your account and our services</li>
                <li>To send promotional communications, if you have opted in</li>
                <li>To comply with legal obligations</li>
              </ul>
            </li>

            <li>
              <h2>Data Sharing and Disclosure</h2>
              <p>We do not sell your personal information to third parties. However, we may share your information
                with:</p>
              <ul>
                <li>Third-party service providers who assist us in operating our platform and conducting our business
                  (e.g., payment processors, marketing partners)
                </li>
                <li>Third-party website partners for marketing or business purposes</li>
                <li>Law enforcement agencies or regulatory bodies if required by law</li>
              </ul>
            </li>

            <li>
              <h2>Data Security</h2>
              <p>We implement a variety of security measures to maintain the safety of your personal information.
                However, please remember that no method of transmission over the Internet or method of electronic
                storage is 100% secure. While we strive to protect your personal information, we cannot guarantee its
                absolute security.</p>
            </li>

            <li>
              <h2>Your Rights</h2>
              <p>You have the right to:</p>
              <ul>
                <li>Access the personal information we hold about you</li>
                <li>Request correction of any inaccuracies in your personal information</li>
                <li>Withdraw consent for us to process your personal information, subject to legal obligations</li>
              </ul>
            </li>

            <li>
              <h2>Changes to This Privacy Policy</h2>
              <p>Tenant Network reserves the right to update this Privacy Policy at any time. Any changes will be posted
                on this page with an updated effective date. We encourage you to review this policy periodically for any
                updates.</p>
            </li>

            <li>
              <h2>Contact Us</h2>
              <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please
                contact us at: <strong>contact@tenantnetwork.in</strong></p>
            </li>
          </ol>


        </div>
      </div>
    </>
)
}

export default Privacy;