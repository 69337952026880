import React, {useState} from 'react';
import './ContactForm.css';
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import toast from "react-hot-toast";
import {addToContactList} from "../../services/listingService"; // Import the API service
import contactImage from '../../assets/Contact/undraw_personal_email_re_4lx7.svg';

const ContactForm = () => {
    // Initial state for form data
    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    // Update form state when input changes
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const addContactData = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loading indicator

        try {
            // API call to the backend service using JSON
            console.log('Submitting form data:', formData);
            const response = await addToContactList(formData); // Send JSON data

            setIsLoading(false); // Stop loading indicator after the request completes

            if (response.data.message) {
                // Show success toast message
                toast.success(response.data.message, {
                    duration: 3000
                });

                // Clear the form after success
                setFormData(initialFormData);

                // Optionally, navigate to another page after successful submission
                //navigate('/thankyou');
            } else {
                // If something went wrong on the server
                toast.error('Something went wrong, please try again.', {
                    duration: 5000
                });
            }
        } catch (err) {
            setIsLoading(false); // Stop loading if there is an error

            // Show error message depending on the error response
            if (err.response && err.response.data && err.response.data.message) {
                toast.error(err.response.data.message, {
                    duration: 5000
                });
            } else {
                toast.error('Something went wrong, please try again.', {
                    duration: 5000
                });
            }
        }
    };

    return (
        <div className="contact-container">
            <div className="image-section">
                <img
                    src={contactImage}
                    alt="Contact Us"
                    className="contact-image"
                />
            </div>
            <div className="form-section">
                <h2 className="contact_us_head">Contact Us</h2>
                <form className="contact-form" onSubmit={addContactData}>
                    <div className="form-group">
                        <label className='contact_label' htmlFor="firstName">First Name</label>
                        <input className='contact_input'
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className='contact_label' htmlFor="lastName">Last Name</label>
                        <input className='contact_input'
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Enter your last name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className='contact_label' htmlFor="email">Email</label>
                        <input className='contact_input'
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className='contact_label' htmlFor="message">Message</label>
                        <textarea className='contact_input'
                            id="message"
                            name="message"
                            placeholder="Enter your message"
                            rows="5"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {isLoading ? <LoadingSpinner/> : (
                        <button type="submit" className="submit-btn" disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Send Message'}
                        </button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ContactForm;
