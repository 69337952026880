// production env
export const BASE_URL = "/api";

//dev env
//export const BASE_URL = "http://139.59.61.32:5000/api";

//export const DEV_COMPANY_ID = "COM-0HMQM0XQSYS9F";
//export const DEV_COUNTRY_ID = "CON-0AZZW6VY5B0SS";
//export const DEV_TOKEN = "Uo0sToOpYeZRCZfppXK0GCzvkNPofuruocxvHpqhoEk";
//export const DEV_PLAN_ID = "PLV-0HMQNDPHDYM1Z";
//export const PROD_PLAN_AMOUNT = "299";
//export const PROD_PLAN_DAYS = "7";


export const PROD_COMPANY_ID = "COM-0HMXXTV2S8FYD";
export const PROD_COUNTRY_ID = "CON-0AZZW6VY5B0SS";
export const PROD_TOKEN = "3q4SIowiI7a1daSJOa_ThNutsmHYIlyb5BcXrpbGKrM";
export const PROD_PLAN_ID = "PLV-0HMXY98C5822B";
export const PROD_PLAN_AMOUNT = 299;
export const PROD_PLAN_DAYS = 7;