import React from 'react'
import { MdWifi, MdStore, MdDinnerDining, MdTheaters, MdOutlinePark, MdLocalGasStation } from "react-icons/md";
import { GiClubs, GiWashingMachine, GiMeditation, GiWalk, GiTennisRacket, GiCctvCamera, GiCarBattery, GiGuards,GiLift,GiSecurityGate,GiWeightLiftingUp } from "react-icons/gi";
import { FaSwimmer, FaClinicMedical, FaFootballBall, FaCar } from "react-icons/fa";
import { TbMassage } from "react-icons/tb";
import '../../pages/PropertyDetail/index.css'



const Amenities = (props) => {
  const amenities = props.amenities ? props.amenities : [];
  return (
    <>
      {
      amenities.includes('Wifi Available') &&
      <div className='facility-container'>
        <span><MdWifi className='icon'/></span>
        <h1>Wifi</h1>
      </div>
    }
    {
      amenities.includes('Power Backup') &&
      <div className='facility-container'>
        <span><GiCarBattery className='icon'/></span>
        <h1>Power Backup</h1>
      </div>
    }
    {
      amenities.includes('Swimming Pool') &&
      <div className='facility-container'>
        <span><FaSwimmer className='icon'/></span>
        <h1>Swimming Pool</h1>
      </div>
    }
    {
      amenities.includes('Security') &&
      <div className='facility-container'>
        <span><GiGuards className='icon'/></span>
        <h1>Security</h1>
      </div>
    }
    {
      amenities.includes('Lift') &&
      <div className='facility-container'>
        <span><GiLift className='icon'/></span>
        <h1>Lift</h1>
      </div>
    }
    {
      amenities.includes('Gated Community') &&
      <div className='facility-container'>
        <span><GiSecurityGate className='icon'/></span>
        <h1>Gated Community</h1>
      </div>
    }
    {
      amenities.includes('Gym') &&
      <div className='facility-container'>
        <span><GiWeightLiftingUp className='icon'/></span>
        <h1>GYM</h1>
      </div>
    }
    {
      amenities.includes('CCTV') &&
      <div className='facility-container'>
        <span><GiCctvCamera className='icon'/></span>
        <h1>CCTV</h1>
      </div>
    }
    {
      amenities.includes('Gas Pipeline') &&
      <div className='facility-container'>
        <span><MdLocalGasStation className='icon'/></span>
        <h1>Gas Pipeline</h1>
      </div>
    }
    {
      amenities.includes('Parking') &&
      <div className='facility-container'>
        <span><FaCar className='icon'/></span>
        <h1>Parking</h1>
      </div>
    }
    {
      amenities.includes('Club House') &&
      <div className='facility-container'>
        <span><GiClubs className='icon'/></span>
        <h1>Club House</h1>
      </div>
    }
    {
      amenities.includes('Indoor Games') &&
      <div className='facility-container'>
        <span><GiTennisRacket className='icon'/></span>
        <h1>Indoor Games</h1>
      </div>
    }
    {
      amenities.includes('Outdoor Games') &&
      <div className='facility-container'>
        <span><FaFootballBall className='icon'/></span>
        <h1>Outdoor Games</h1>
      </div>
    }
    {
      amenities.includes('Spa') &&
      <div className='facility-container'>
        <span><TbMassage className='icon'/></span>
        <h1>Spa</h1>
      </div>
    }
    {
      amenities.includes('Garden') &&
      <div className='facility-container'>
        <span><MdOutlinePark className='icon'/></span>
        <h1>Garden</h1>
      </div>
    }
    {
      amenities.includes('Joggers Track') &&
      <div className='facility-container'>
        <span><GiWalk className='icon'/></span>
        <h1>Joggers Track</h1>
      </div>
    }
    {
      amenities.includes('Banquet') &&
      <div className='facility-container'>
        <span><MdDinnerDining className='icon'/></span>
        <h1>Banquet</h1>
      </div>
    }
    {
      amenities.includes('Theatre') &&
      <div className='facility-container'>
        <span><MdTheaters className='icon'/></span>
        <h1>Theatre</h1>
      </div>
    }
    {
      amenities.includes('Departmental Store') &&
      <div className='facility-container'>
        <span><MdStore className='icon'/></span>
        <h1>Departmental Store</h1>
      </div>
    }
    {
      amenities.includes('Meditation Zone') &&
      <div className='facility-container'>
        <span><GiMeditation className='icon'/></span>
        <h1>Meditation Zone</h1>
      </div>
    }
    {
      amenities.includes('Laundry Facility') &&
      <div className='facility-container'>
        <span><GiWashingMachine className='icon'/></span>
        <h1>Laundry Facility</h1>
      </div>
    }
    {
      amenities.includes('Medical Store') &&
      <div className='facility-container'>
        <span><FaClinicMedical className='icon'/></span>
        <h1>Medical Store</h1>
      </div>
    }
    </>
  )
}

export default Amenities