import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import '../MyProperties/index.css';
import Card2 from '../../components/card2/Card2.jsx';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import {AuthContext} from '../../context/authContext';
import {searchProperties} from "../../services/listingService";

const PropertyList = () => {
    const {currentUser, currentLocation, locationVisibility, searchFilters} = useContext(AuthContext);
    const location = useLocation();
    const state = location.state;
    const [properties, setProperties] = useState([]);
    const [heading, setHeading] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [city, setCity] = useState(searchFilters?.city || currentLocation);
    const [filters, setFilters] = useState(searchFilters?.filters || {});
    useEffect(() => {
        if (location.search.includes('propertyList')) {
            setHeading(state.head);
            updateFilters(state.data);
        }
    }, [state, location.search]);

    const updateFilters = (data) => {
        setFilters({property_type: data, location: city});
    };

    useEffect(() => {
        const fetchProperties = async () => {
            setIsLoading(true);

            try {
                const res = await searchProperties({user_id: currentUser?.id, ...filters});
                setProperties(res.data.properties);
            } catch (err) {
                window.alert('Something went wrong');
                console.log('search err---', err);
            } finally {
                setIsLoading(false);
            }
        };

        if (filters.property_type) {
            fetchProperties();
        }
    }, [filters, currentUser]);

    useEffect(() => {
        const changeLocation = async (visibility) => {
            try {
                await locationVisibility(visibility);
            } catch (err) {
                console.log('Error in enabling location visibility');
            }
        };
        changeLocation(false);
    }, [locationVisibility]);

    return (
        <div className='myproperties-page'>
            <div className='myproperties-page-head'>
                <h1>{heading}</h1>
            </div>
            {isLoading ? (
                <div className='home-loader'>
                    <LoadingSpinner/>
                </div>
            ) : (
                properties && (
                    <div className='myproperties-page-content'>
                        <div className='myproperties-cards'>
                            {properties.map((property) => (
                                <Card2 key={property.id} property={property}/>
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default PropertyList;
