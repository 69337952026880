import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './BlogCard.css';

const BlogCard = (props) => {
  let blogDetails = props.blog? props.blog : {};
  

  return (
    <>
      <div className='blog-card-container'>
        <div className='img-con'>
          <Link to={`/blog?blog_title=${blogDetails.title}`}>
            <img src={blogDetails.image} alt='img'/>
          </Link>
        </div>
        <div className='blog-data'>
          <div className='data-history'>
            <span>{blogDetails.author_name} -</span>
            <span>{moment(blogDetails.created_on).format("LL")}</span>
          </div>
          <div className='data-head'>
            <Link to={`/blog?blog_title=${blogDetails.title}`}>
              <h3>{blogDetails.title}</h3>
            </Link>
          </div>
          <div className='data-summary'>
            <Link to={`/blog?blog_title=${blogDetails.title}`}>
              <p>{blogDetails.summary}</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogCard;