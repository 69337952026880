import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.css';

const PropertyCarousel = ({ images }) => {
    const settings = {
        dots: images.length > 1, // Show dots only if there are multiple images
        infinite: images.length > 1, // Infinite loop only if there are multiple images
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: images.length > 1, // Show arrows only if there are multiple images
        draggable: false,   // Disable mouse drag
        swipe: false,       // Disable swipe on touch devices
    };

    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index} className="carousel-slide">
                    <img src={image} alt={`Slide ${index}`} />
                </div>
            ))}
        </Slider>
    );
};

// Usage in your main component:
/*<div className='card-img-sec'>
    <Link to={`/propertydetail/${propertyDetails.id}`}>
        <PropertyCarousel images={propertyDetails && propertyDetails.images} />
    </Link>
    <div onClick={() => addWishlist(propertyDetails && propertyDetails.id)} className='wishlist-icon'>
        {propertyDetails.wishlisted ? <AiFillHeart className='icon' /> : <AiOutlineHeart className='icon' />}
    </div>
    <div className='property-type-tag'>
        <p>{propertyDetails && propertyDetails.property_type}</p>
    </div>
</div>*/

export default PropertyCarousel