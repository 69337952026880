import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";

import "./Login.css";
import { MdClose } from "react-icons/md";
import toast from 'react-hot-toast';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { sendOtpService, verifyOtp } from "../../services/userService";
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { AuthContext } from "../../context/authContext";
//import {MX_TRACK} from "../../util/Mixpanel";

const verifyFields=()=>{
  let otp = document.getElementById('otp');
  if(otp.value.length<1){
    document.getElementById("otp").classList.add('field-alert');
    toast.error("Please Enter OTP",{
      duration:3000
    });
    return 0;
  }
  return 1;
}


const WhatsappLogin = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [user,setUser] = useState({
    mobile_no: '',
    otp: '',
  });
  const navigate = useNavigate();
  
  const { setCurrentUser } = useContext(AuthContext);
  const [otpSend,setOtpSend] = useState(false);

  let name,value;
  const handleInput=(e)=>{
    e.preventDefault();
    name = e.target.name;
    value = e.target.value
    setUser({...user,[name]:value});

    if ( document.getElementById("otp")?.classList.contains('field-alert')){
      document.getElementById("otp").classList.remove('field-alert');
    }
  }

  //calling api
  const handleSendOtp= async (e)=>{
    e.preventDefault();
    try{
      await sendOtpService(user.mobile_no);
      setOtpSend(true)
    }
    catch(err){
      console.log(err.response.data.message);
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }
  const handleSubmit= async (e)=>{
    e.preventDefault();
    try{
      let checkFields = verifyFields();
      if(checkFields){
        setIsLoading(true);
        const userDetails = await verifyOtp(user);
        setCurrentUser(userDetails.user);
        /*MX_TRACK(`User_logged`, {
          distinct_id: user && user.mobile_no ? user.mobile_no : 'Guest',
        });*/
        toast.success("Login Success",{
          duration:2000
        });
        setIsLoading(false);
        navigate("/");
      }
    }
    catch(err){
      console.log(err.response.data.message);
      setIsLoading(false);
      if(err.response.data.message === 'Incorrect OTP'){
        document.getElementById("otp").classList.add('field-alert');
      }
      toast.error(err.response.data.message,{
        duration:5000
      });
    }
  }

  return (
    <>
      <section className="login-container forget-pass-container">
        <div className="login-head">
          <h1>Whatsapp Login&nbsp;<WhatsAppIcon className='whatsIcon' fontSize="13px"/></h1>
        </div>
        <div className="login-form reset-pass-form">
          <form className="forget-pass-form">
            <input name="mobile_no" onChange={handleInput} value={user.mobile_no} type="number" placeholder="Whatsapp Number"/>
            <button onClick={handleSendOtp}>Send OTP</button>
            {
              otpSend &&
                <span className="send-otp">Check Your Whatsapp for OTP</span>
            }
          </form>
          {
            otpSend ? 
              <form className="reset-pass-form">
                <input id="otp" name="otp" disabled={!otpSend} onChange={handleInput} value={user.otp} type="number" placeholder="Enter OTP"/>
                
                <span id="passMatchString" className="password-match-string"></span>
                {
                  isLoading? <LoadingSpinner/> : 
                              <button onClick={handleSubmit}>Login</button>
                }
              </form>
              : null
          }
        </div>

        {/* close btn */}
        <div className="close-con">
          <Link to={`/`}>
            <MdClose/>
          </Link>
        </div>
      </section>
    </>
  )
}

export default WhatsappLogin;