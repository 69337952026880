import React from 'react'
import './index.css';
import {Helmet} from "react-helmet";

const Refund = () => {
return (
    <>
      <div className='terms-page'>
        <Helmet>
          <title>{"Refund Policy"}</title>
          <meta name="description" content={"Tenant Network Refund Policies"}/>
          <link rel='canonical' href='/refund'/>
        </Helmet>
        <div className='terms-page-head'>
          <h1>Refund Policy</h1>
        </div>
        <div className='terms-page-content'>
          <p><strong>Effective Date: 1st October 2024</strong></p>

          <p>At Tenant Network, we strive to provide our users with a seamless property search experience. Please review
            our refund policy carefully:</p>

          <ol>
            <li>
              <h2>No Refunds</h2>
              <p>All subscription fees paid for the use of Tenant Network are non-refundable. Once a subscription is
                purchased, it cannot be canceled or refunded under any circumstances.</p>
            </li>

            <li>
              <h2>Property Availability</h2>
              <p>While we make every effort to ensure that the listings on our platform are accurate and up-to-date, we
                cannot guarantee the availability of any listed properties. Property availability is subject to change
                without notice, and we do not hold any responsibility for properties that may no longer be available at
                the time of your inquiry.</p>
            </li>

            <li>
              <h2>User Responsibility</h2>
              <p>It is the user's responsibility to verify the availability and details of any property before
                proceeding with any agreements or commitments. Tenant Network is a platform that connects tenants
                directly with property owners or co-tenants, and we do not assume liability for any discrepancies or
                issues related to property availability.</p>
            </li>

            <li>
              <h2>Changes to This Policy</h2>
              <p>Tenant Network reserves the right to modify this Refund Policy at any time. Any changes will be posted
                on this page with an updated effective date. We encourage users to review this policy periodically for
                any updates.</p>
            </li>

            <li>
              <h2>Contact Us</h2>
              <p>If you have any questions or concerns regarding this Refund Policy, please contact us
                at: <strong>contact@tenantnetwork.in</strong></p>
            </li>
          </ol>


        </div>
      </div>
    </>
)
}

export default Refund;