import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Typography } from '@mui/material';
import {
    PROD_COMPANY_ID,
    PROD_COUNTRY_ID,
    PROD_PLAN_AMOUNT,
    PROD_PLAN_DAYS,
    PROD_PLAN_ID,
    PROD_TOKEN,
} from '../services/helper';

function SubscriptionDialog({ open, onClose, title, message }) {
    const [subscriptionDetails, setSubscriptionDetails] = React.useState({
        amount: '',
        days: '',
    });
    const priceWidgetRef = React.useRef(null);

    const getListing = async (USER, COMPANY_ID, COUNTRY_ID, PLAN_ID, TOKEN, amount, days) => {
        try {
            if (!USER || !USER.id) {
                console.error('User data not found or invalid in localStorage');
                return;
            }

            setSubscriptionDetails({ amount, days });

            if (priceWidgetRef.current) {
                priceWidgetRef.current.innerHTML = `<stykite-widget 
                    company-id="${COMPANY_ID}" 
                    country-id="${COUNTRY_ID}"
                    email="${USER.email ? USER.email : 'null'}"
                    customer-identifier="${USER.id}"
                    widget-type="PLAN_BUTTON"
                    label="Subscribe"
                    plan-tsid="${PLAN_ID}"
                    frequency="WEEKLY"
                    button-css="color: white; background-color: #1976d2; border: 1px #1976d2 solid; border-radius: 5px"
                    api-key="${TOKEN}"></stykite-widget>`;
            }
        } catch (err) {
            console.error('fetch subscription details error: ', err);
        }
    };

    React.useEffect(() => {
        const COMPANY_ID = PROD_COMPANY_ID;
        const COUNTRY_ID = PROD_COUNTRY_ID;
        const TOKEN = PROD_TOKEN;
        const USER = JSON.parse(localStorage.getItem('user'));
        const PLAN_ID = PROD_PLAN_ID;
        const amount = PROD_PLAN_AMOUNT;
        const days = PROD_PLAN_DAYS;

        if (open) {
            getListing(USER, COMPANY_ID, COUNTRY_ID, PLAN_ID, TOKEN, amount, days);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            TransitionProps={{
                onEntered: () => {
                    const USER = JSON.parse(localStorage.getItem('user'));
                    const COMPANY_ID = PROD_COMPANY_ID;
                    const COUNTRY_ID = PROD_COUNTRY_ID;
                    const PLAN_ID = PROD_PLAN_ID;
                    const TOKEN = PROD_TOKEN;
                    const amount = PROD_PLAN_AMOUNT;
                    const days = PROD_PLAN_DAYS;

                    getListing(USER, COMPANY_ID, COUNTRY_ID, PLAN_ID, TOKEN, amount, days);
                },
            }}
        >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 380 }}>
                <DialogContentText style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    <Typography color="#000">{message}</Typography>
                    <Typography>
                        Subscription Details: <span style={{ color: '#000', fontWeight: '600' }}>{subscriptionDetails.days}-day access</span>
                    </Typography>
                    <Typography>
                        Subscription Cost: <span style={{ color: '#000', fontWeight: '600' }}>{subscriptionDetails.amount}/-</span>
                    </Typography>
                    <Typography>
                        What's Included: All Access to our platform for <span style={{ color: '#000', fontWeight: '600' }}>{subscriptionDetails.days} Days!</span>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined" autoFocus>
                    Cancel
                </Button>
                <div id="priceWidget" ref={priceWidgetRef}></div>
            </DialogActions>
        </Dialog>
    );
}

export default SubscriptionDialog;
