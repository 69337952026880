import axios from "axios";
import { BASE_URL } from "./helper";



const uploadImageService =async(form)=>{
  const res = await axios.post(`${BASE_URL}/listing/imageUpload`,form,{withCredentials:true},{
    headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data`,
    }
  
  });
  return res;
}

const publishBlogService =async(blogData)=>{
  const res = await axios.post(`${BASE_URL}/blog/addBlog`,{...blogData},{withCredentials:true});
  return res;
}

const updateBlogService =async(blogData)=>{
  const res = await axios.post(`${BASE_URL}/blog/updateBlog`,{...blogData},{withCredentials:true});
  return res;
}

const deleteBlogService =async(id,user_id)=>{
  const res = await axios.post(`${BASE_URL}/blog/deleteBlog`,{id,user_id},{withCredentials:true});
  return res;
}

// get
const getBlogs =async(search,page,category)=>{
  let res;
  if(search.length){
    res = await axios.get(`${BASE_URL}/blog/getBlogs?search=${search}&page=${page}&category=${category}`,{withCredentials:true});
  }
  else{
    res = await axios.get(`${BASE_URL}/blog/getBlogs?page=${page}&category=${category}`,{withCredentials:true});
  }
  return res;
}
// get
const getBlogByTitle =async(title)=>{
  const res = await axios.get(`${BASE_URL}/blog/getBlogByTitle?title=${title}`,{withCredentials:true});
  return res;
}

const getBlogCategories =async()=>{
  const res = await axios.get(`${BASE_URL}/blog/getBlogCategories`,{withCredentials:true});
  return res;
}





export { 
  uploadImageService,
  publishBlogService,
  getBlogs,
  getBlogByTitle,
  updateBlogService,
  deleteBlogService,
  getBlogCategories
}